import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Drawer } from 'antd';


import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import ConfirmStonePopup from './ConfirmStonePopup';
import QuotePopup from './QuotePopup';

import OfficeSidebar from './OfficeSidebar';
import {
  handleInsertTrack,
  compareStones,
  LISTINGPAGES,
  handlePrint,
  handleMemoPrint,
  handleTrackDelete,
  handleUpsertTrack,
  getPath,
  handleBidDelete,
  handleNoteDelete,
  handleDeleteOffice,
  isMobile,
  handleDownloadExcel,
  myAccountExcelTrack,
  myAccountExcelMemo,
  myAccountExcelOffice,
  myAccountNotesExcel,
  myBidExcel,
  matchPairExcelTrack,
  featureStoneExcelTrack,
  myAccountPDFTrack,
  myAccountPDFMemo,
  myAccountPDFOffice,
  myAccountNotesPDF,
  myBidPDF,
  DiamondTrackStatusUpdate,
  myHoldExcelTrack,
  myHoldPDFTrack,
  matchPairPDFTrack,
  featureStonePDFTrack,
  handleBidCartCreate,
  releaseBlockedDiamonds,
  handleMoveToBidCart,
} from '../../DiamondList/DiamondListFunctions';
import Pagination from '../Pagination';
import OpenNotification from '../CommonButton/OpenNotification';
import isEmpty from 'lodash/isEmpty';
import FinalCalculations from './FinalCalculations';
import ShareDropdown from './ShareDropdown';
import HoldPopup from './HoldPopup';
import DownloadDropdown from './DownloadDropdown';
import { trackTypeObj, LOCAL_STORAGE_VAR } from '../../../constants/Common';
import Reminder from '../Reminder';
import UpdateReminder from '../UpdateReminder';
import BidPopup from './BidPopup';
import DealoftheDayPopup from './DealOfDayPopup';
import IntlMessages from '../../../util/IntlMessages';
import { setCount } from '../../../appRedux/actions/deleteReminder';
import CommonModal from '../CommonModal';
import WatchlistPopup from './WatchlistPopup';
import MobileShareOption from './MobileShareOption';
import NotePopup from './NotePopup';
import EnquiryPopup from './EnquiryPopup';
import { useSelector } from 'react-redux';
import { GuestUserPopup } from '../../GuestUserPopup';
import MobileDownloadOption from './MobileDownloadOption';
import map from 'lodash/map';
import compact from 'lodash/compact';
import get from 'lodash/map';
import UtilService from '../../../services/util';
import { useHistory } from 'react-router-dom';
import { useBoolean } from 'util/hooks';

const DiamondListingAction = (props) => {
  const history = useHistory();

  const selectedRows = useSelector((state) => state.diamondData.selectedRows[props.currentType]);
  const checked =
    window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
      window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
      ? props.checked
      : props.checkedData ?? selectedRows ?? [];

  const [visible, setVisible] = useState('');
  const [moreSearch, moreSearchOption] = useState(false);
  const [more, setMore] = useState(false);
  const [reminder, setReminder] = useState(false);
  const [disclaimer, setDisclaimer] = useState(false);
  const [showStone, setShowStone] = useBoolean(false);
  const [showAppointment, setShowAppointment] = useBoolean(false);
  const [showStoneNotIn, setShowStoneNotIn] = useBoolean(false);
  const [showGuest, setShowGuest] = useState(false);

  const isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);
  const isMobileView = isMobile();
  const moreSearchDrawer = () => {
    moreSearchOption(true);
  };
  const onClose = () => {
    localStorage.setItem("counterKey", 0)
    setVisible('');
    setReminder('');
    // props.clearAll();
    moreSearchOption(false);
    props.setCount(!props.deleteReminder.count);
  };

  const path = window.location.pathname.split('/')[1];
  const module = window.location.pathname.split('/')[2];
  const { showData } = useSelector((store) => store?.auth);

  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  const actions = [
    !props.bidStone
      ? null
      : {
        name: <span>Make Deal</span>,
        key: 'bid',
        img: require('../../../assets/svg/DiamondList/bid-stone.svg'),
        onClick: () => {
          const selectTerm = localStorage.getItem('Terms');
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to bid.',
              });
            } else {
              setVisible('bid');
            }
            // else if (selectTerm === 'true' && getPath() !== LISTINGPAGES.COMPARE) {
            //   OpenNotification({
            //     type: 'error',
            //     title: 'You need to select term before Bid Stone(s)',
            //   });
            // }
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.dealOfTheDay
      ? null
      : {
        name: <IntlMessages id="app.bidStone" />,
        key: 'bid',
        img: require('../../../assets/svg/DiamondList/bid-stone.svg'),
        onClick: () => {
          const selectTerm = localStorage.getItem('Terms');
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to bid.',
              });
            } else {
              setVisible('bid');
            }
            // else if (selectTerm === 'true' && getPath() !== LISTINGPAGES.COMPARE) {
            //   OpenNotification({
            //     type: 'error',
            //     title: 'You need to select term before Bid Stone(s)',
            //   });
            // }
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.createBidCartStone
      ? null
      : {
        name: <span>Add to Bid Cart</span>,
        key: 'createbidcart',
        img: require('../../../assets/svg/DiamondList/cart.svg'),
        onClick: async () => {
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to bid cart.',
              });
            } else {
              await handleBidCartCreate(checked);
              // history.push('/my-bid?cart');
            }
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.myBid
      ? null
      : {
        name: <IntlMessages id="app.myBid" />,
        key: 'mybid',
        img: require('../../../assets/svg/DiamondList/my-bid.svg'),
        onClick: () => {
          if (!isGuest) {
            history.push('/my-bid');
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.myBidDealList && get(USER_PERMIT, 'MY_BID.view', false)
      ? null
      : {
        name: <span>My Deal Of The Day</span>,
        key: 'deal',
        img: require('../../../assets/svg/DiamondList/my-bid.svg'),
        onClick: () => {
          if (!isGuest) {
            history.push('/my-deal-of-the-day');
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.cartbidStone
      ? null
      : {
        name: <IntlMessages id="app.bidStone" />,
        key: 'mybidCart',
        img: require('../../../assets/svg/DiamondList/my-bid.svg'),
        onClick: () => {
          if (!isGuest) {
            props.cartBidStoneCreate(checked);
            props.fetch();
            props.clearAll();
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.cartbidStoneMobile
      ? null
      : {
        name: <IntlMessages id="app.bidStone" />,
        key: 'mybidCart',
        img: require('../../../assets/svg/DiamondList/my-bid.svg'),
        onClick: () => {
          if (!isGuest) {
            props.cartBidStoneMobileCreate();
            props.clearAll();
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.myBidCartStone
      ? null
      : {
        name: <span>My Bid Cart</span>,
        key: 'mybidcart',
        img: require('../../../assets/svg/DiamondList/my-bid-cart.svg'),
        onClick: () => {
          if (!isGuest) {
            history.push('/my-bid?cart');
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.cancel
      ? null
      : {
        name: <span>Cancel</span>,
        key: 'mybidcart',
        img: require('../../../assets/svg/DiamondList/my-bid-cart.svg'),
        onClick: props.onClose,
      },
    props.delete
      ? {
        name: <IntlMessages id="app.removeStone" />,
        img: require('../../../assets/svg/delete.svg'),
        key: 'delete',
        onClick: () => {
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to delete.',
              });
              return;
            }
            if (getPath() === LISTINGPAGES.MYBID) {
              handleBidDelete(
                checked.map((row) => row.trackId),
                (flag) => {
                  props.fetch();
                  props.clearAll();
                  if (flag) props.setCount(!props.deleteReminder.count);
                },
              );
            } else if (getPath() === LISTINGPAGES.NOTE) {
              handleNoteDelete(
                checked.map((row) => row.trackId),
                (flag) => {
                  props.clearAll();
                  if (flag) {
                    props.fetch();
                    props.setCount(!props.deleteReminder.count);
                  }
                },
              );
            } else if (getPath() === LISTINGPAGES.HOLD) {
              releaseBlockedDiamonds(
                checked.map((row) => row.id),
                (flag) => {
                  props.clearAll();
                  if (flag) {
                    props.fetch();
                  }
                },
              );
            } else if (getPath() === LISTINGPAGES.OFFICE) {
              const schedule = checked.map((ck) => ({ diamonds: [ck.id], id: ck.slottId }));

              handleDeleteOffice({ schedule: schedule }, () => {
                props.clearAll();
                props.fetch();
                OpenNotification({
                  type: 'success',
                  title: 'Your stone(s) is removed from office successfully.',
                });
              });
            } else {
              handleTrackDelete(
                props.trackType,
                checked.map((row) => row.id),
                props.inTrackDiamonds,
                (flag) => {
                  props.clearAll();
                  if (flag) {
                    props.fetch();
                    props.setCount(!props.deleteReminder.count);
                  }
                },
              );
            }
          } else {
            setShowGuest(true);
          }
        },
      }
      : null,
    props.update
      ? {
        name: <IntlMessages id="app.updateStone" />,
        img: require('../../../assets/svg/DiamondList/updatestone.svg'),
        key: 'update',
        onClick: () => {
          if (!isGuest) {
            if (getPath() === LISTINGPAGES.REMINDER) {
              isEmpty(checked)
                ? OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) to update reminder.',
                })
                : setReminder('update');
            } else if (
              getPath() === LISTINGPAGES.MYBID ||
              getPath() === LISTINGPAGES.MYDEALOFDAYLIST ||
              getPath() === LISTINGPAGES.DEALOFDAYLIST
            ) {
              isEmpty(checked)
                ? OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) to update bid.',
                })
                : setVisible('bid');
            } else if (getPath() === LISTINGPAGES.NOTE) {
              isEmpty(checked)
                ? OpenNotification({
                  type: 'error',
                  title: 'Please select stone(s) to update note.',
                })
                : setVisible('note');
            }
          } else {
            setShowGuest(true);
          }
        },
      }
      : null,
    props.noconfirm
      ? null
      : {
        name: <IntlMessages id="app.confirmStone" />,
        key: 'confirm',
        disabled:
          checked.some(({ wSts }) => ['M', 'H', 'BH'].includes(wSts)) &&
          !compact(checked.map((c) => ['M', 'H', 'BH'].includes(c.wSts) && c.id)).every((e) => {
            const allIds = [
              ...map(props.inBlockDiamonds && props.inBlockDiamonds[0] && props.inBlockDiamonds[0].diamonds, 'id'),
              ...map(props.inBlockDiamonds && props.inBlockDiamonds[1] && props.inBlockDiamonds[1].diamonds, 'id'),
            ];
            return allIds.includes(e);
          }),

        onClick: () => {
          localStorage.setItem("confirmStonePopupCounter", 0);
          localStorage.setItem("confirmStonePopup", showStone)
          const selectTerm = localStorage.getItem('Terms');
          if (checked.find((chk) => chk.wSts == 'SW')) {
            setShowStone.true();
          } else if (checked.find((chk) => chk.countryNm === 'USA')) {
            setShowStoneNotIn.true();
          } else if (!isGuest) {
            if (
              window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
              window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
            ) {
              setVisible('confirm');
            }

            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to confirm.',
              });
            } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
            } else if (checked.find((chk) => chk.wSts === 'U')) {
              OpenNotification({
                type: 'error',
                title: 'Please connect with your sales representative for more information',
                // 'Sorry, you can not perform any action on upcoming stone(s).',
              });
            } else if (checked.find((chk) => chk.wSts === 'H' || chk.wSts === 'M')) {
              OpenNotification({
                type: 'error',
                title: `You can't Confirm (${checked.map((item) => { if (item.wSts === 'H' || item.wSts === 'M') { return item.vStnId } })}) In Business Stones.Please contact to Sales person.`,
              });
            } else {
              //setDisclaimer('confirm');
              setVisible('confirm');
            }
            // }else if (selectTerm === 'true') {
            //   OpenNotification({
            //     type: 'error',
            //     title: 'You need to select term before confirm',
            //   });
            // } else {
            //   setDisclaimer('confirm');
            // }
          } else {
            setShowGuest(true);
          }
        },
        img: require('../../../assets/svg/DiamondSearch/confirm.svg'),
      },
    props.noreminder
      ? null
      : {
        name: <IntlMessages id="app.reminder" />,
        key: 'reminder',
        img: require('../../../assets/svg/DiamondList/reminder.svg'),
        onClick: () => {
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to add in reminder.',
              });
            } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
              // } else if (checked.find((chk) => chk.wSts === 'U')) {
              //   OpenNotification({
              //     type: 'error',
              //     title: 'Sorry, you can not perform any action on upcoming stone(s).',
              //   });
            } else {
              setReminder('insert');
            }
          } else {
            setShowGuest(true);
          }
        },
      },
    !props.rejectOffer
      ? null
      : {
        name: 'Reject',
        key: 'rejectoffer',
        onClick: () => {
          isEmpty(checked)
            ? OpenNotification({
              type: 'error',
              title: 'Please select stone(stones) to reject offer.',
            })
            : DiamondTrackStatusUpdate(
              checked.map((x) => x.trackId),
              { offerStatus: 3 },
              () => {
                props.clearAll();
                props.fetch();
              },
            );
        },
      },

    props.nocart
      ? null
      : {
        name: <IntlMessages id="app.Cart" />,
        key: 'cart',
        onClick: () => {
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to add in cart.',
              });
            } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
              // } else if (checked.find((chk) => chk.wSts === 'U')) {
              //   OpenNotification({
              //     type: 'error',
              //     title: 'Sorry, you can not perform any action on upcoming stone(s).',
              //   });
            } else {
              handleInsertTrack(trackTypeObj.CART, checked, (status) => {
                props.clearAll();
                if (status) props.setCount(!props.deleteReminder.count);
              });
            }
          } else {
            setShowGuest(true);
          }
        },
        img: require('../../../assets/svg/DiamondList/cart.svg'),
      },
    props.nowatch
      ? null
      : {
        name: <IntlMessages id="app.Watch" />,
        key: 'watch',
        onClick: () => {
          if (!isGuest) {
            if (
              window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
              window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
            ) {
              setVisible('watchlist');
            } else if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to add in watch.',
              });
            } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
            } else {
              setVisible('watchlist');
            }
          } else {
            setShowGuest(true);
          }
        },
        img: require('../../../assets/svg/DiamondList/watch.svg'),
      },
    // props.noreminder
    //   ? null
    //   : {
    //       name: <IntlMessages id="app.reminder" />,
    //       key: "reminder",
    //       onClick: () => {
    //         if (!isGuest) {
    //           isEmpty(checked)
    //             ? OpenNotification({
    //                 type: "error",
    //                 title: "Please select stone(s) to add in reminder.",
    //               })
    //             : setVisible("insert");
    //         } else {
    //           setShowGuest(true);
    //         }
    //       },
    //     },
    // props.noquote
    //   ? null
    //   : {
    //       name: <IntlMessages id="app.Offer" />,
    //       key: 'quote',
    //       disabled:
    //         //isEmpty(props.inBlockDiamonds) &&
    //         checked.some(({ wSts }) => ['M', 'H', 'BH'].includes(wSts)) &&
    //         !compact(checked.map((c) => ['M', 'H', 'BH'].includes(c.wSts) && c.id)).every((e) => {
    //           const allIds = [
    //             ...map(props.inBlockDiamonds && props.inBlockDiamonds[0] && props.inBlockDiamonds[0].diamonds, 'id'),
    //             ...map(props.inBlockDiamonds && props.inBlockDiamonds[1] && props.inBlockDiamonds[1].diamonds, 'id'),
    //           ];

    //           return allIds.includes(e);
    //         }),
    //       onClick: () => {
    //         if (!isGuest) {
    //           if (
    //             window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
    //             window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
    //           ) {
    //             setVisible('quote');
    //           }
    //           if (isEmpty(checked)) {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'Please select stone(s) to apply quote.',
    //             });
    //           } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
    //             });
    //           } else if (checked.find((chk) => chk.wSts === 'U')) {
    //             OpenNotification({
    //               type: 'error',
    //               title: 'Sorry, you can not perform any action on upcoming stone(s).',
    //             });
    //           } else {
    //             setVisible('quote');
    //           }
    //           // else {
    //           //   let fm = checked.filter(
    //           //     (el) => getNewStatus(el) && el.crt <= 3
    //           //   );
    //           //   if (fm.length === 0) setVisible("quote");
    //           //   else
    //           //     OpenNotification({
    //           //       type: "error",
    //           //       title:
    //           //         "Packet No. " +
    //           //         checked.map((x) => x.vStnId).join(", ") +
    //           //         (fm.length === 1 ? " is" : " are") +
    //           //         " not eligible for offer.",
    //           //     });
    //           // }
    //         } else {
    //           setShowGuest(true);
    //         }
    //       },
    //       img: require('../../../assets/svg/DiamondDetail/offer.png'),
    //     },
    props.noenquiry
      ? null
      : {
        name: 'Enquiry',
        key: 'enquiry',
        disabled:
          checked.some(({ wSts }) => ['M', 'H', 'BH'].includes(wSts)) &&
          !compact(checked.map((c) => ['M', 'H', 'BH'].includes(c.wSts) && c.id)).every((e) => {
            const allIds = [
              ...map(props.inBlockDiamonds && props.inBlockDiamonds[0] && props.inBlockDiamonds[0].diamonds, 'id'),
              ...map(props.inBlockDiamonds && props.inBlockDiamonds[1] && props.inBlockDiamonds[1].diamonds, 'id'),
            ];

            return allIds.includes(e);
          }),
        onClick: () => {
          if (!isGuest) {
            if (
              window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
              window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
            ) {
              setVisible('enquiry');
            }
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) for enquiry.',
              });
            } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
            } else if (checked.find((chk) => chk.wSts === 'U')) {
              OpenNotification({
                type: 'error',
                title: 'Sorry, you can not perform any action on upcoming stone(s).',
              });
            } else {
              setVisible('enquiry');
            }
          } else {
            setShowGuest(true);
          }
        },
        img: require('../../../assets/svg/enquiry-full.svg'),
      },
    !props.moveToBidCart
      ? null
      : {
        name: <span>Move To Bid Cart</span>,
        key: 'moveToBidCart',
        onClick: async () => {
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to bid cart.',
              });
            } else if (checked.find((chk) => chk?.wSts !== 'BA' && chk?.wSts !== 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can move only bid stones to the bid cart',
              });
            } else {
              await handleMoveToBidCart(checked, props?.fetch);
            }
          } else {
            setShowGuest(true);
          }
        },
      },
    props.nohold
      ? null
      : {
        name: 'Hold',
        key: 'hold',
        onClick: () => {
          // if (!isGuest) {
          const selectTerm = localStorage.getItem('Terms');
          if (
            window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
            window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
          ) {
            setVisible('hold');
          }
          if (isEmpty(checked)) {
            OpenNotification({
              type: 'error',
              title: 'Please select stone(s) for hold.',
            });
          } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
            OpenNotification({
              type: 'error',
              title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
            });
          } else if (checked.find((chk) => chk.wSts === 'U')) {
            OpenNotification({
              type: 'error',
              title: 'Sorry, you can not perform any action on upcoming stone(s).',
            });
          } else {
            setVisible('hold');
          }
          // else if (selectTerm === 'true') {
          //   OpenNotification({
          //     type: 'error',
          //     title: 'You need to select term before hold',
          //   });
          // }
          // } else {
          //   setShowGuest(true);
          // }
        },
        img: require('../../../assets/svg/DiamondDetail/reminder.svg'),
      },
    props.nofinalcalc
      ? null
      : {
        name: <IntlMessages id="app.FinalCalculation" />,
        key: 'calc',
        img: require('../../../assets/svg/DiamondList/cal.svg'),
        onClick: () => {
          localStorage.setItem("confirmStonePopupCounter", 0);
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) for final calculation.',
              });
            } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
            } else if (checked.find((chk) => chk.wSts === 'U')) {
              OpenNotification({
                type: 'error',
                title: 'Please connect with your sales representative for more information.',
              });
            } else {
              setVisible('finalCalc');
              // let fm = checked.filter((el) => el.isFm === "ELIG");
              // if (fm.length === checked.length || fm.length === 0) setVisible("finalCalc");
              // else
              //   OpenNotification({
              //     type: "error",
              //     title: "Please select FM stones separately.",
              //   });
            }
          } else {
            setShowGuest(true);
          }
        },
      },
    props.nooffice
      ? null
      : {
        name: <IntlMessages id="app.Office" />,
        key: 'office',
        img: require('assets/svg/DiamondList/office-view.svg'),
        onClick: () => {
          if (checked.find((chk) => chk.countryNm === 'USA')) {
            return setShowAppointment.true();
          }
          if (isGuest) return setShowGuest(true);

          if (
            window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
            window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
          ) {
            return setVisible('office');
          }

          if (isEmpty(checked)) {
            return OpenNotification({ type: 'error', title: 'Please select stone(s) for office.' });
          }

          // if (checked.some((chk) => chk.wSts === 'U')) {
          //   return OpenNotification({
          //     type: 'error',
          //     title: 'Sorry, you can not perform any action on upcoming stone(s).',
          //   });
          // }

          setVisible('office');
        },
      },
    !props.saveNote
      ? null
      : {
        name: <span>Save Note</span>,
        key: 'savenote',
        img: require('../../../assets/svg/DiamondList/my-bid-cart.svg'),
        onClick: props.onSave,
      },
    props.nonote
      ? null
      : {
        name: <IntlMessages id="app.comment" />,
        key: 'note',
        onClick: () => {
          if (!isGuest) {
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to add note.',
              });
            } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
              // } else if (checked.find((chk) => chk.wSts === 'U')) {
              //   OpenNotification({
              //     type: 'error',
              //     title: 'Sorry, you can not perform any action on upcoming stone(s).',
              //   });
            } else {
              setVisible('note');
            }
          } else {
            setShowGuest(true);
          }
        },
      },
    props?.showRefresh
      ? {
        name: 'Refresh',
        key: 'refresh',
        img: require('../../../assets/svg/DiamondList/reminder.svg'),
        onClick: () => {
          history.push(`/${LISTINGPAGES.SHOW}`);
        },
      }
      : null,
    props.nonocompare
      ? null
      : props.nocompare
        ? {
          name: (
            <span>
              <IntlMessages id="app.CompareStoneNow" />
              {' (' + props.data.length + ')'}
            </span>
          ),
          key: 'compare',
          img: require('../../../assets/svg/DiamondList/compare.svg'),
          onClick: () => {
            if (!isGuest) {
              localStorage.setItem(`${LOCAL_STORAGE_VAR}-more`, true);
              history.push(JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)));
            } else if (checked.find((chk) => chk.wSts === 'U')) {
              OpenNotification({
                type: 'error',
                title: 'Sorry, you can not perform any action on upcoming stone(s).',
              });
            } else {
              setShowGuest(true);
            }
          },
        }
        : {
          name: <IntlMessages id="app.CompareStone" />,
          key: 'compare',
          img: require('../../../assets/svg/DiamondList/compare.svg'),
          onClick: () => {
            if (!isGuest) {
              if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
                });
              } else if (checked.find((chk) => chk.wSts === 'U') && path === 'account') {
                OpenNotification({
                  type: 'error',
                  title: 'Sorry, you can not perform any action on upcoming stone(s).',
                });
              } else {
                compareStones(checked, props.currentType);
                // props.clearAll();
              }
            } else {
              setShowGuest(true);
            }
          },
        },

    props.noshare
      ? null
      : {
        name: <IntlMessages id="app.Share" />,
        key: 'share',
        img: require('../../../assets/svg/DiamondList/share.svg'),
        onClick: () => {
          if (!isGuest) {
            if (
              window.location.pathname.split('/')[1] === LISTINGPAGES.DETAIL ||
              window.location.pathname.split('/')[1] === LISTINGPAGES.DNA
            ) {
              setVisible('share');
            }
            if (isEmpty(checked)) {
              OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to share.',
              });
            } else if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
            } else {
              setVisible('share');
            }
          } else {
            setShowGuest(true);
          }
        },
      },
    props.nodownload
      ? null
      : {
        name: <IntlMessages id="app.Download" />,
        key: 'download',
        img: require('../../../assets/svg/DiamondList/download.svg'),
        onClick: () => {
          if (!isGuest) {
            if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
            } else if (checked.length > 0) {
              setVisible('download');
            } else {
              if (props.data.length > 0) {
                if (path === 'account') {
                  if (module === 'watchlist') {
                    myAccountExcelTrack(2);
                  } else if (module === 'cart') {
                    myAccountExcelTrack(1);
                  } else if (module === 'quote-list') {
                    myAccountExcelTrack(4);
                  } else if (module === 'reminder') {
                    myAccountExcelTrack(5);
                  } else if (module === 'enquiry') {
                    myAccountExcelTrack(6);
                  } else if (module === 'hold') {
                    myHoldExcelTrack(1);
                  } else if (module === 'confirm-list') {
                    myAccountExcelMemo();
                  } else if (module === 'office-list') {
                    myAccountExcelOffice();
                  } else if (module === 'notes') {
                    myAccountNotesExcel();
                  }
                } else if (path === 'my-bid') {
                  myBidExcel();
                } else if (path === 'match-pair') {
                  matchPairExcelTrack(window.location.search.split('?')[1]);
                } else if (path === 'stone-of-the-day') {
                  featureStoneExcelTrack(11);
                } else if (path === 'compare') {
                  // confirmCompare is to check whether the diamond is in /confirm-list or not
                  const confirmCompare = localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                    : false;
                  if (confirmCompare) {
                    //
                    handleDownloadExcel(
                      {},
                      localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
                        ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
                        : [],
                      (status) => {
                        if (status) {
                          //props.clearAll();
                        }
                      },
                      path === 'upcoming' ? true : false,
                      [],
                      confirmCompare,
                    );
                  } else {
                    //
                    handleDownloadExcel(
                      {},
                      localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
                        ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
                        : [],
                      (status) => {
                        if (status) {
                          //props.clearAll();
                        }
                      },
                      path === 'upcoming' ? true : false,
                      [],
                      confirmCompare,
                    );
                  }
                } else {
                  handleDownloadExcel(
                    props.seachFilterTag ? props.seachFilterTag.searchData : {},
                    checked.map((x) => x.id),
                    () => { },
                  );
                }
              } else {
                return OpenNotification({
                  type: 'error',
                  title: 'No Stones Found',
                });
              }
            }
          } else {
            setShowGuest(true);
          }
        },
      },
    props.noprint
      ? null
      : {
        name: <IntlMessages id="app.PrintList" />,
        key: 'print',
        img: require('../../../assets/svg/DiamondList/print-list.svg'),
        onClick: () => {
          if (!isGuest) {
            if (checked.find((chk) => chk.wSts === 'B') && path === 'account') {
              OpenNotification({
                type: 'error',
                title: 'You can not add Bid stone(s) for any action. Please uncheck/remove bid stone(s).',
              });
            } else if (checked.length > 0) {
              if (module === 'confirm-list') {
                handlePrint(
                  {},
                  checked.map((chk) => chk.diamondId),
                );
              } else if (path === 'compare') {
                // confirmCompare is to check whether the diamond is in /confirm-list or not
                const confirmCompare = localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                  ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                  : false;
                if (confirmCompare) {
                  handleMemoPrint(
                    {},
                    checked.map((chk) => chk.diamondId),
                  );
                } else {
                  handlePrint(
                    {},
                    checked.map((chk) => chk.id),
                  );
                }
              } else {
                handlePrint(
                  {},
                  checked.map((chk) => chk.id),
                );
              }
            } else {
              if (props.data.length > 0) {
                if (path === 'account') {
                  if (module === 'watchlist') {
                    myAccountPDFTrack(2);
                  } else if (module === 'cart') {
                    myAccountPDFTrack(1);
                  } else if (module === 'quote-list') {
                    myAccountPDFTrack(4);
                  } else if (module === 'reminder') {
                    myAccountPDFTrack(5);
                  } else if (module === 'enquiry') {
                    myAccountPDFTrack(6);
                  } else if (module === 'hold') {
                    myHoldPDFTrack(1);
                  } else if (module === 'confirm-list') {
                    myAccountPDFMemo();
                  } else if (module === 'office-list') {
                    myAccountPDFOffice();
                  } else if (module === 'notes') {
                    myAccountNotesPDF();
                  }
                } else if (path === 'match-pair') {
                  matchPairPDFTrack(window.location.search.split('?')[1]);
                } else if (path === 'stone-of-the-day') {
                  featureStonePDFTrack(11);
                } else if (path === 'my-bid') {
                  myBidPDF();
                  // } else if (path === "upcoming") {
                  //   upcomingPDF([{ wSts: "U" }]);
                } else if (path === 'compare') {
                  // confirmCompare is to check whether the diamond is in /confirm-list or not
                  const confirmCompare = localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                    : false;
                  if (confirmCompare) {
                    handleMemoPrint(
                      {},
                      localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
                        ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
                        : [],
                    );
                  } else {
                    handlePrint(
                      {},
                      localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`)
                        ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-compare-diamond`))
                        : [],
                    );
                  }
                } else {
                  handlePrint(props.seachFilterTag ? props.seachFilterTag.searchData : {}, {});
                }
              } else {
                return OpenNotification({
                  type: 'error',
                  title: 'No Stones Found',
                });
              }
            }
            // props.clearAll();
          } else {
            setShowGuest(true);
          }
        },
      },
    props.sharexray
      ? {
        name: <>Share X-Ray</>,
        key: 'xray',
        onClick: () => {
          if (!isGuest) {
            props.shareXray();
          } else {
            setShowGuest(true);
          }
        },
        img: require('../../../assets/svg/DiamondList/certi.svg'),
      }
      : null,
    props.viewcerti
      ? {
        name: <>Verify Certificate</>,
        key: 'certi',
        onClick: () => {
          if (!isGuest) {
            props.verifyCerti();
            onClose();
          } else {
            setShowGuest(true);
          }
        },
      }
      : null,
    props.downloadzip
      ? {
        name: <>Download Zip</>,
        key: 'zip',
        onClick: () => {
          if (!isGuest) {
            props.downloadZip();
            onClose();
          } else {
            setShowGuest(true);
          }
        },
      }
      : null,
  ].filter((el) => el !== null);
  const ipad = window.innerWidth <= 991 ? true : false;
  const noConfirmButton = getPath() === LISTINGPAGES.MYBID || props.fsNow === LISTINGPAGES.MYBID;

  const lastSomeBtn = 3;
  return (
    <>
      <div className="mobileSearchBottom">
        {props.modify && (
          <div className="mobileSearchActionItem">
            <a onClick={props.onModify}>
              <img alt="" src={require('../../../assets/img/Mobileback.svg')} />
              <span>
                <IntlMessages id="app.modify" />
              </span>
            </a>
          </div>
        )}
        {actions.slice(0, actions.length > 5 ? 4 : 5).map((x) => {
          return (
            <div className="mobileSearchActionItem" key={x.key}>
              <a onClick={x.onClick}>
                <img alt="" src={x.img} />
                <span>{x.key === 'calc' ? <IntlMessages id="app.FinalCalc" /> : x.name}</span>
              </a>
            </div>
          );
        })}
        {actions.length > 5 && (
          <div className="mobileSearchActionItem">
            <a onClick={moreSearchDrawer}>
              <img alt="" src={require('../../../assets/svg/DiamondSearch/more.svg')} />
              <span>More</span>
            </a>
          </div>
        )}
      </div>
      {moreSearch && (
        <Drawer title={false} onClose={onClose} visible={moreSearch} wrapClassName="mobileSidebar mobileBottomSidebar">
          <div className="MoreSearchOptionItem">
            <ul>
              {actions.slice(4, actions.length).map((x) => {
                return (
                  <li onClick={x.onClick} key={x.key}>
                    <a>{x.name}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </Drawer>
      )}

      <div
        className={
          'botoomStickyBar diamondListingAction new-d-flex' +
          (props.ActionFalse === false ? ' justify-content-center' : '')
        }
      >
        <div className="d-flex listingAction">
          {props.modify && (
            <button className="bottomStickyButton" onClick={props.onModify}>
              <IntlMessages id="app.modify" />
            </button>
          )}
          {actions.slice(0, ipad ? 3 : actions.length > 8 ? actions.length - lastSomeBtn : actions.length).map((x) => {
            return (
              <a key={x.key} className={`bottomStickyButton ${x.disabled && `disable`}`} onClick={x.onClick}>
                <span>{x.name}</span>
              </a>
            );
          })}
          {((ipad && actions.length > 3) || actions.length > 7) && (
            <Dropdown
              isOpen={more}
              toggle={() => {
                setMore(!more);
              }}
              onClick={() => {
                setMore(!more);
              }}
            >
              <DropdownToggle className="bottomStickyButton" caret>
                <span>
                  <IntlMessages id="app.More" />
                </span>
              </DropdownToggle>
              <DropdownMenu className="diamondListBottomDropdown">
                <ul>
                  {actions.slice(ipad ? 3 : actions.length - lastSomeBtn, actions.length).map((x, i) => {
                    return (
                      <li key={i + 'li'} onClick={x.onClick}>
                        <a>
                          <div className="dropdownBottomItem">
                            <span>{x.name}</span>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
        {props.nopaginate ? null : <Pagination {...props} />}
      </div>

      {showStone && (
        <CommonModal
          visible={showStone}
          handleOk={() => {
            setShowStone.false();
            setVisible('confirm');
          }}
          handleCancel={() => {
            localStorage.setItem("counterKey", 0)
            setShowStone.false();
            setVisible('');
          }}
          title="Confirm Stone"
          modalSize="xs-size"
          submitTitle="Yes"
          cancelTitle="No"
          footerShow
        >
          <div style={{ textAlign: 'center' }}>
            <IntlMessages id="app.PacketNo" /> :
            {checked
              .filter((x) => x.wSts === 'SW')
              .map((x) => x.vStnId)
              .join(', ')}

            &nbsp;
            <IntlMessages id="app.showStone" /> {`${showData.deliveryDate}.`}
            &nbsp;
            <IntlMessages id="app.showStoneTwo" />
          </div>
        </CommonModal>
      )}

      {showStoneNotIn && (
        <CommonModal
          visible={showStoneNotIn}
          handleOk={() => {
            setShowStoneNotIn.false();
            setVisible('confirm');
          }}
          handleCancel={() => {
            localStorage.setItem("counterKey", 0)
            setShowStoneNotIn.false();
            setVisible('');
          }}
          title="Confirm Stone"
          modalSize="xs-size"
          submitTitle="Yes"
          cancelTitle="No"
          footerShow
        >
          <div style={{ textAlign: 'center' }}>

            Packet No. {checked
              .filter((x) => x.countryNm === 'USA')
              .map((x) => x.vStnId)
              .join(', ')} is currently in transit and you may receive delivery after 29th Aug 2024.
          </div>
        </CommonModal>
      )}
      {showAppointment && (
        <CommonModal
          visible={showAppointment}
          handleOk={() => {
            setShowAppointment.false();
            let a = []
            let ck = checked
              .filter((x) => x.countryNm === 'USA')
              .map((x) => a.push(x.vStnId))
              .join(', ')
            if (checked.find((x) => x.countryNm !== 'USA')) {

              setVisible('office');
            } else {
              setVisible('');

            }
          }}
          handleCancel={() => {

            setShowAppointment.false();
            setVisible('');
          }}
          title="Appointment"
          modalSize="xs-size"
          submitTitle={checked.find((x) => x.countryNm !== 'USA') ? "Continue" : "Ok"}
          cancelTitle="No"
          footerShow={
            checked.find((x) => x.countryNm !== 'USA') ? true : false
          }
          footerShowOne={checked.find((x) => x.countryNm !== 'USA') ? false : true}
        >
          <div style={{ textAlign: 'center' }}>
            {getPath() === LISTINGPAGES.BID ? `Packet No.  ${checked
              .filter((x) => x.countryNm === 'USA')
              .map((x) => x.vStnId)
              .join(', ')} is currently in transit, you need to submit a blind bid for this stone(s)` : ` Packet No. ${checked
                .filter((x) => x.countryNm === 'USA')
                .map((x) => x.vStnId)
                .join(', ')} is currently in transit, you can book an appointment after 29th Aug 2024. `}

          </div>
        </CommonModal>
      )}
      {visible === 'confirm' && (
        <Drawer title={false} onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <ConfirmStonePopup {...props} checked={checked} onClose={onClose} promotionDis={props.promotionDis} />
        </Drawer>
      )}
      {/* {visible === 'quote' || props.editOffer ? (
        <Drawer
          title={false}
          onClose={() => {
            onClose();
            if (props.editOffer) props.fetch();
          }}
          visible={true}
          wrapClassName="diamondListinSidebar offerPopup xl-size"
        >
          <QuotePopup
            quoteDay={getPath() === LISTINGPAGES.QUOTEDAY}
            {...props}
            checked={checked}
            offerDis={props.offerDis}
            onClose={() => {
              onClose();
              if (props.editOffer) props.fetch();
            }}
          />
        </Drawer>
      ) : null} */}
      <Drawer onClose={onClose} visible={visible === 'bid'} wrapClassName="diamondListinSidebar xl-size" destroyOnClose>
        {visible === 'bid' && getPath() !== LISTINGPAGES.DEALTHEOFDAY && getPath() !== LISTINGPAGES.MYDEALOFDAYLIST ? (
          <BidPopup {...props} bid={props?.bid ? props?.bid : props?.bidConfig?.data} checked={checked} onClose={onClose} />
        ) : (
          <DealoftheDayPopup {...props} checked={checked} onClose={onClose} />
        )}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'note'}
        wrapClassName="diamondListinSidebar xl-size"
        destroyOnClose
      >
        {visible === 'note' && <NotePopup {...props} checked={checked} onClose={onClose} />}
      </Drawer>
      <Drawer
        onClose={onClose}
        visible={visible === 'office'}
        wrapClassName="diamondListinSidebar OfficePopup"
        destroyOnClose
      >
        {visible === 'office' && (
          <OfficeSidebar
            {...props}
            quoteDay={getPath() === LISTINGPAGES.QUOTEDAY}
            checked={checked}
            onClose={onClose}
          />
        )}
      </Drawer>
      {visible === 'finalCalc' && (
        <Drawer title={false} onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <FinalCalculations
            {...props}
            checked={checked}
            onClose={onClose}
            noConfirmButton={noConfirmButton}
            promotionDis={props.promotionDis}
          />
        </Drawer>
      )}
      {visible === 'watchlist' && (
        <Drawer title={false} onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <WatchlistPopup {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )}
      {visible === 'enquiry' && <EnquiryPopup visible={true} checked={checked} handleCancel={onClose} />}
      {visible === 'hold' && (
        <Drawer
          title={false}
          onClose={onClose}
          visible={visible === 'hold'}
          wrapClassName="diamondListinSidebar xl-size"
        >
          <HoldPopup {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )}
      {visible === 'share' && (
        <CommonModal modalSize="md-size" title="Share Stone" handleCancel={onClose} visible={true} wrapClassName="">
          <ShareDropdown {...props} checked={checked} onCloseSharePopup={onClose} />
        </CommonModal>
      )}
      {/* {visible === 'share' && isMobileView === true && (
        <Drawer title={false} onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <MobileShareOption {...props} checked={checked} onClose={onClose} />
        </Drawer>
      )} */}
      {visible === 'download' && (
        <CommonModal
          modalSize="md-size"
          title={<IntlMessages id="app.Download" />}
          handleCancel={onClose}
          visible={true}
        >
          <DownloadDropdown
            {...props}
            checked={checked}
            orderDiamond={
              window.location.pathname === '/account/confirm-list' ||
                (localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                  ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                  : false)
                ? true
                : false
            }
            onCloseDownloadPopup={onClose}
          />
        </CommonModal>
      )}
      {/* {visible === 'download' && isMobileView === true && (
        <CommonModal
          modalSize="md-size"
          title={<IntlMessages id="app.Download" />}
          handleCancel={onClose}
          visible={true}
        >
          <DownloadDropdown
            {...props}
            checked={checked}
            orderDiamond={
              window.location.pathname === '/account/confirm-list' ||
              (localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`)
                ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-confirm-compare`))
                : false)
                ? true
                : false
            }
            onCloseDownloadPopup={onClose}
          />
        </CommonModal>
      )} */}
      {reminder === 'insert' && (
        <Drawer title={false} onClose={onClose} visible={true} wrapClassName="diamondListinSidebar">
          <Reminder
            handleCancel={() => {
              setReminder(false);
              //props.clearAll();
            }}
            handleSetReminder={(e) =>
              handleInsertTrack(
                trackTypeObj.REMINDER,
                checked,
                () => {
                  //props.clearAll();
                  setReminder(false);
                },
                e,
              )
            }
            checked={checked}
          />
        </Drawer>
      )}
      {reminder === 'update' && (
        <Drawer title={false} onClose={onClose} visible={true} wrapClassName="diamondListinSidebar xl-size">
          <UpdateReminder
            handleCancel={() => {
              setReminder(false);
              //props.clearAll();
            }}
            date={checked && checked[0] ? checked[0].reminderDate : null}
            handleUpdateReminder={(e) =>
              handleUpsertTrack(
                trackTypeObj.REMINDER,
                checked,
                props.inTrackDiamonds,
                () => {
                  //props.clearAll();
                  props.fetch();
                  setReminder(false);
                },
                e,
              )
            }
            checked={checked}
          />
        </Drawer>
      )}
      {checked && checked.length ? (
        <CommonModal
          handleCancel={() => setDisclaimer(false)}
          handleOk={() => {
            setVisible(disclaimer);
            setDisclaimer(false);
          }}
          visible={disclaimer}
          modalSize="xs-size"
          footerShow
          title={<IntlMessages id="app.Disclaimer" />}
          submitTitle={<IntlMessages id="app.IAgree" />}
          cancelTitle={<IntlMessages id="app.Quit" />}
        >
          {checked.map((x) => (x.locNm ? x.locNm.toUpperCase() : '')).filter((el) => el !== 'INDIA').length ? (
            <p>
              <IntlMessages id="app.PacketNo" />:{checked.map((x) => x.vStnId).join(', ')},
              <IntlMessages id="app.isLocatedIn" />
              {checked.map((x) => (x.locNm ? x.locNm.toUpperCase() : '')).join('/')}
              <IntlMessages id="app.notAvail" />
              <br />
              <p className="mt-10">
                <IntlMessages id="app.pandemicNotice" />
              </p>
            </p>
          ) : (
            <p>
              <IntlMessages id="app.PacketNo" />:{checked.map((x) => x.vStnId).join(', ')},{' '}
              <IntlMessages id="app.deliverIndia" />
            </p>
          )}
        </CommonModal>
      ) : null}
      {showGuest && (
        <GuestUserPopup
          visible={showGuest}
          register={() => {
            window.open('/signup', '_self');
            window.localStorage.clear();
            window.sessionStorage.clear();
          }}
          handleCancel={() => {
            setShowGuest(false);
          }}
        />
      )}
    </>
  );
};

export default connect(
  (store) => ({
    deleteReminder: store.deleteReminder,
    selectedRows: store.diamondData.selectedRows,
  }),
  { setCount },
)(DiamondListingAction);
