import React, { useState, useEffect, useMemo } from 'react';
import { InputNumber } from 'antd';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import { calculate, formatNumber } from './SelectStone';
import groupBy from 'lodash/groupBy';
import Heading from '../Heading';
import findIndex from 'lodash/findIndex';
import {
  handleInsertTrack,
  getColumn,
  getPath,
  isMobile,
  LISTINGPAGES,
  getDiamondBlockMaster,
  handleAddBlock,
} from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import SelectStone from './SelectStone';
import IntlMessages from '../../../util/IntlMessages';
import { trackTypeObj, DIAMOND_BID, SELECT_STONE_TERMS_NEW } from '../../../constants/Common';
import OpenNotification from '../CommonButton/OpenNotification';
import { HeadingCalc } from './ConfirmStonePopup';
import { getBidType } from '../../NewArrivalBidIt';
import { useSelector } from 'react-redux';
import WatchPopupMobile from './WatchPopupMobile';
import InputBlock from '../InputBlock';
import TextArea from '../TextArea';
import { Select, Row, Col } from 'antd';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import HoldStone from './HoldStone';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const { Option } = Select;
const currentType = 'HoldStonePopup';

const HoldStonePopup = (props) => {
  const [columns, setColumns] = useState([]);
  const [comment, setComment] = useState('');
  const [exp, setExp] = useState(true);
  const [data, setData] = useState(props.checked.map((x) => ({ ...x, expBack: x.back - 1 })));
  const [minTime, setMinTime] = useState();
  const [maxTime, setMaxTime] = useState();
  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  const [blockMaster, setBlockMaster] = useState({});
  const [time, setTime] = useState(undefined);
  const [timeError, setTimeError] = useState(false);
  const [cm, setCm] = useState(false);
  const [settings, setSettings] = useState([]);
  const term = localStorage.getItem('Terms');
  const [termCal, setTermCal] = useState(term === 'true' ? '' : term);

  const getFMCharge = ({ shpNm, crt, clrNm, colNm }) => {
    let charge = 0;
    if (settings.length) {
      let grp = groupBy(settings, 'shp');
      let shaped = shpNm === 'ROUND' ? grp['ROUND'] : grp['FANCY'];
      let carated = shaped.filter((el) => (el.from ? el.from <= crt : true) && (el.to ? el.to >= crt : true));
      if (carated.length) {
        let found = carated.filter((el) => !el.clr && !el.col);
        if (!found.length)
          found = carated.filter(
            (el) =>
              (el.col && el.col.split(',').includes(colNm) ? true : false) &&
              (el.clr && el.clr.split(',').includes(clrNm) ? true : false),
          );
        if (found.length) charge = found[0].charges;
      }
    }
    return charge;
  };

  const getCmCharge = (arr) => {
    let charge = 0;
    arr.map((line) => {
      if (line.isCm && line.isCm === 'ELIG') {
        let found = settings.filter((el) => el.from <= line.crt && (el.to ? el.to >= line.crt : true));
        if (found.length) charge += found[0].fee;
      }
      return true;
    });
    return charge;
  };

  useEffect(() => {
    let Columns = getColumn();
    let index = findIndex(Columns, { id: 'back' });
    if (!index) index = 1;
    let columns = [...Columns.slice(0, index + 1)];
    columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      props.checked[0].isFm === 'ELIG'
        ? {
            Header: 'FM Charges',
            accessor: 'fmCharge',
            id: 'fmCharge',
            Cell: ({ cell }) => <>{parseFloat(cell.value)}</>,
          }
        : null,
      // {
      //   Header:
      //     'Final Disc(' +
      //     parseFloat(getExtraPer()) +
      //     ((getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MYBID) &&
      //     getBidType() === DIAMOND_BID.TYPE.BLIND
      //       ? '+0.5'
      //       : '') +
      //     '%)',
      //   accessor: 'fnBack',
      //   id: 'fnBack',
      //   Cell: ({ row }) => parseFloat(row.original.calcDiscount),
      // },
      // {
      //   Header: 'Final Rate',
      //   accessor: 'fnCtpr',
      //   id: 'fnCtpr',
      //   Cell: ({ row }) => parseFloat(row.original.calcPricePerCarat),
      // },
      // {
      //   Header: 'Final Value',
      //   accessor: 'fnAmt',
      //   id: 'fnAmt',
      //   Cell: ({ row }) => <>{parseFloat(row.original.calcAmount)}</>,
      // },
      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ];
    setColumns(columns.filter((el) => el && el));
  }, [exp]);

  useEffect(() => {
    getDiamondBlockMaster('H', (data) => {
      setBlockMaster(data);
      setMinTime(data.blockTimes[0]);
      setMaxTime(data.blockTimes[data.blockTimes.length - 1]);
      //setTimeDuratoins(data.blockTimes);
    });
  }, []);

  const getTerms = () => {
    const term = localStorage.getItem('Terms');
    return (
      <div>
        <select
          name="termCal"
          value={termCal}
          placeholder="Select Terms"
          onChange={(e) => {
            // let s = calculate(checked, 2)
            localStorage.setItem('Terms', false);
            setTermCal(e.target.value);
          }}
        >
          {/* <option value='00' disabled>Select Terms</option> */}
          <option value="" disabled selected hidden>
            Select Terms
          </option>
          {SELECT_STONE_TERMS_NEW.map((x) => (
            <option value={x.id}>{x.name}</option>
          ))}
        </select>
      </div>
    );
  };

  let newPricedRows = checked; //.map(x => newDiamondPrice(x, 'charge' + fm))
  // let summation = calculate(newPricedRows);
  let summation = useMemo(() => calculate(newPricedRows, termCal), [checked, termCal]);
  let totalPieces = summation.total_pieces;
  let totalCarat = parseFloat(summation.total_carat).toFixed(2);
  let rapPrice = summation.final_rapaport;
  let avgDisc = parseFloat(summation.final_discount).toFixed(2);
  let avgPr = summation.final_price;

  let netValue = formatNumber(parseFloat(summation.final_value).toFixed(2));

  let addDis = parseFloat(summation.addDis).toFixed(2);
  let sysDis = summation.sysDis;
  let finalNetRate = formatNumber(parseFloat(summation.final_rate).toFixed(2));
  let sysAmtDisc = Number(summation.final_value) * (Number(summation.sysDis) / 100);
  let finalAvgDisc = parseFloat(Number(summation.final_back)).toFixed(2);
  let finalNetValue = formatNumber(parseFloat(summation.final_net_value).toFixed(2));
  let CmCharge = parseFloat(getCmCharge(newPricedRows)).toFixed(2);
  let terms = getTerms();
  localStorage.setItem('TermsDis', addDis);
  let VALUES = isMobile()
    ? {
        'Total Pieces': totalPieces,
        'Total Carats:': totalCarat,
        'Rap Prices': rapPrice,
        'Avg. Discount(%)': avgDisc,
        'Total Pr/Ct': avgPr,
        'Net Value': netValue,
        'Amt Discount(%)': sysDis,
        'Terms Discount(%)': Number(addDis) <= 0 ? addDis : `+${addDis}`,
        'Final Discount(%)': finalAvgDisc,
        'Final Net Rate': finalNetRate,
        'Final Net Value': finalNetValue + (cm && CmCharge > 0 ? ' + $' + CmCharge + ' (CM Charges)' : ''),
        TERMS: terms,
      }
    : [
        { title: 'Total Pieces', value: totalPieces },
        { title: 'Total Carats', value: totalCarat },
        { title: 'Rap Prices', value: rapPrice },
        { title: 'Avg. Discount(%)', value: avgDisc },
        { title: 'Total Pr/Ct', value: avgPr },

        { title: 'Net Value', value: netValue },

        { title: 'Amt Discount(%)', value: sysDis },
        { title: 'Terms Discount(%)', value: Number(addDis) <= 0 ? addDis : `+${addDis}` },
        { title: 'Final Discount(%)', value: finalAvgDisc },
        { title: 'Final Net Rate', value: finalNetRate },
        {
          title: 'Final Net Value',
          value: finalNetValue + (cm && CmCharge > 0 ? ' + $' + CmCharge + ' (CM Charges)' : ''),
        },
        {
          title: 'TERMS',
          value: terms,
        },
      ];

  const dateHandleChange = (e, val) => {
    let da = null;
    da = parseFloat(e).toFixed(2);
    if (Number(e) < minTime || Number(e) > maxTime) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
    setTime(da);
  };

  const getHoldInput = () => {
    return (
      // <Row gutter={16}>
      //   <Col md={12}>
      <div className="confirmListOption mt-10">
        <div className="commonTextArea from-group">
          <label>Enter Hold Time (Hours) *</label>
          {/* <Select
              value={time}
              onChange={(value) => setTime(value)}
              getPopupContainer={(trigger) => {
                return trigger;
              }}
              placeholder="Select Time"
              dropdownClassName="selectDopdown"
            >
              {!isEmpty(blockMaster) &&
                blockMaster.blockTimes.map((t) => (
                  <Option value={t}>{t}</Option>
                ))}
            </Select> */}
          <InputNumber
            placeholder={'Enter Hours'}
            style={{ margin: 0 }}
            type="number"
            value={time}
            onChange={(e) => dateHandleChange(e)}
            // parser={(val) => Number(parseFloat(val))}
            // formatter={(val) =>
            //   isNumber(Number(val)) ? `${Number(parseFloat(val))}` : val
            // }
          />
          {timeError && (
            <span className="err_span">
              {`Enter Hold Time (Hours) * Min Hold Time: ${minTime} Hour | Max Hold Time: ${maxTime} Hours`}
            </span>
          )}
        </div>
        {/* </Col> */}
        {/* <Col md={12}> */}
        <div className="commonTextArea from-group">
          <label>Comment</label>
          <textarea value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
        </div>
        {/* </Col>
      </Row> */}
      </div>
    );
  };

  const btnAction = () => {
    return (
      <div className="sideBarPopupButton">
        <a
          onClick={() => {
            if (!checked.length) {
              return OpenNotification({
                type: 'error',
                title: 'Please select stone(s) to hold.',
              });
            }
            let diams = [];
            let tempTime = time && time.split('.');
            let _time = moment()
              .add({
                hours: tempTime && tempTime[0],
                minutes: tempTime && tempTime[1],
              })
              .toISOString();
            checked.map((c) => {
              diams.push({
                diamond: c.id,
                blockPricePerCarat: c.ctPr,
                blockAmount: c.amt,
                vStnId: c.vStnId,
                blockSetting: blockMaster.id,
              });
            });
            // const selectTerm = localStorage.getItem('Terms');
            if (time) {
              handleAddBlock(1, diams, _time, comment, (cb) => {
                if (cb) {
                  props.onClose();
                  props.clearAll();
                }
              });
            }
            // else if (selectTerm === 'true') {
            //   OpenNotification({
            //     type: 'error',
            //     title: 'You need to select term before hold',
            //   });
            // }
            else {
              OpenNotification({
                type: 'error',
                title: 'Please select hold valid till',
              });
            }
          }}
          disabled={timeError}
          className="commonButton"
        >
          Hold Stone
        </a>
        <a className="commonButton" onClick={props.onClose}>
          <IntlMessages id="app.Cancel" />
        </a>
      </div>
    );
  };
  return isMobile() ? (
    <HoldStone
      data={data}
      VALUES={VALUES}
      currentType={currentType}
      parent={{ btnAction, getHoldInput, onClose: props.onClose }}
      //  noConfirmButton={props.noConfirmButton}
    />
  ) : (
    <div>
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <div className="d-flex align-items-center mr-40">
          <Heading className="popupInnerTitle mb-0" title="Hold Stone" />
        </div>
        {data.length > 0 && (
          <SelectStone sum={{}} buy checked={data} profilListing={false ? 'profileStonSelect' : null} />
        )}
        {HeadingCalc(checked)}
      </div>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll watchpopup">
          <Table
            data={data}
            columns={columns}
            areAllChecked={true}
            noGrp
            canSort={false}
            currentType={currentType}
            noCheckBox
          />
        </div>
        <div className="DiamondDetailPopup mt-10">
          {VALUES?.map((x) => {
            return (
              <div className="DiamondDetailPopupItem">
                <span>{x.title}:</span>
                <span>
                  {x.title === 'Avg. Discount(%)' || x.title === 'Final Discount(%)'
                    ? x.value > 0
                      ? `+${x.value}`
                      : x.value
                    : x.value}
                </span>
              </div>
            );
          })}
        </div>
        <div className="offerBottomBox flex-wrap mt-20">{getHoldInput()}</div>

        {btnAction()}
      </div>
    </div>
  );
};

export default HoldStonePopup;
