import React from 'react';

export const AllStatusShow = (props) => {
  return (
    // <ul className="diamond_legends">
    //   <li>
    //     <span className="legend_clr avail"></span>
    //     <span className="legend_name">Available (A)</span>
    //   </li>
    //   <li>
    //     <span className="legend_clr bus"></span>
    //     <span className="legend_name">In Business Process (M)</span>
    //   </li>
    //   <li>
    //     <span className="legend_clr bid"></span>
    //     <span className="legend_name">Bid (B)</span>
    //   </li>
    //   <li>
    //     <span className="legend_clr up"></span>
    //     <span className="legend_name">Upcoming (U)</span>
    //   </li>
    //   <li>
    //     <span className="legend_clr expoGold"></span>
    //     <span className="legend_name">Show (SW)</span>
    //   </li>
    // </ul>
    <ul className="diamond_legends">
      {!props?.show && (
        <>
          <li>
            <span className="legend_clr avail"></span>
            <span className="legend_name avail">Available (A)</span>
          </li>
          <li>
            <span className="legend_clr bus"></span>
            <span className="legend_name bus">In Business (I)</span>
          </li>
          <li>
            <span className="legend_clr bid"></span>
            <span className="legend_name bid">Bid (B)</span>
          </li>
          <li>
            <span className="legend_clr up"></span>
            <span className="legend_name up">New Arrival (U)</span>
          </li>
        </>
      )}
      <li>
        <span className="legend_clr expoGold"></span>
        <span className="legend_name expoGold">Show (S)</span>
      </li>
    </ul>
  );
};
