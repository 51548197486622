import React, { Component } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import { StoneDetailRoute } from 'routes/StoneDetailRoute';

import queryString from 'query-string';
import Dashboard from '../components/Dashboard/index';
import Compare from '../components/DiamondCompare';
import DiamondSearch from '../containers/DiamondSearch';
import MainApp from './../App';
import { commonLogout, LOCAL_STORAGE_VAR, IS_QUICK_SEARCH } from '../constants/Common';
import QuickSearch from '../components/QuickSearch';
import DiamondList from '../components/DiamondList';
import Account from '../components/Account';
import PrivacyNotice from '../components/PrivacyNotice';
import NewArrivalBidIt from '../components/NewArrivalBidIt';
import DealOfDay from '../components/DealOfDay';
import NotificationDropdown from '../components/common/Header/NotificationDropdown';
import SavedSearch from '../components/SavedSearch';
import MyDemand from '../components/MyDemand/index';
import PersonalInformation from '../components/Account/PersonalInformation';
import CompanyInformation from '../components/Account/CompanyInformation';
import ChangePassword from '../components/Account/ChangePassword';
import MobileAccount from '../components/Account/MobileAccount';
import DiamondDetail from '../components/DiamondDetail';
import UtilService from '../services/util';
import DiamondColumnListing from '../components/Account/ManageDiamondSequence';
import BidList from '../components/DiamondList/BidList';
import BidDealOfTheDay from '../components/DiamondList/BidDealOfTheDay';
import TrackShipment from '../components/TrackShipment';
import Offer from '../components/Offer';
import Notifications from '../components/Notifications';
import { get, isEmpty } from 'lodash';
import NewBidCartList from 'components/DiamondList/NewBidListMobile';
import { compose } from 'redux';
import { connect } from 'react-redux';
//import DNA from '../components/DNA';

class FrontRoute extends Component {
  redirectPage = () => {
    const path = window.location.pathname.split('/')?.[1];
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    // const SHOW = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-show-details`);
    if (isEmpty(USER_PERMIT)) commonLogout();

    if (path && path === 'device') {
      const values = queryString.parse(this.props.location.search);
      if (values && values.token) UtilService.setLocalStorageItem(values.token, `${LOCAL_STORAGE_VAR}-token`);
    }

    // const isAuthPage = authorizedPages.includes(path);
    const isTrusted = localStorage.getItem(`${LOCAL_STORAGE_VAR}-isTrusted`);

    const isLoggedInUser = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-token`);

    if (isLoggedInUser && isLoggedInUser.length && isTrusted === 'false') {
      return <Redirect to="/verify-otp" />;
    } else if (!isLoggedInUser || !isLoggedInUser.length) {
      window.location.assign('/signin');
    }
  };

  render() {
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const isMobile = window.innerWidth <= 767;

    return (
      <MainApp loading={this.props.commonData.loading}>
        <Switch>
          {this.redirectPage()}
          {get(USER_PERMIT, 'DASHBOARD.view', false) && <Route exact path="/dashboard" component={Dashboard} />}
          {get(USER_PERMIT, 'SEARCH_LIST.view', false) && <Route exact path="/diamond-list" component={DiamondList} />}
          {get(USER_PERMIT, 'SAVE_SEARCH.view', false) && isMobile && (
            <Route exact path="/account/saved-search" component={SavedSearch} />
          )}
          {get(USER_PERMIT, 'DEMAND.view', false) && isMobile && (
            <Route exact path="/account/my-demand" component={MyDemand} />
          )}
          {get(USER_PERMIT, 'SHIPMENT.view', false) && isMobile && (
            <Route exact path="/account/my-shipment" component={MyDemand} />
          )}
          {IS_QUICK_SEARCH && get(USER_PERMIT, 'QUICK_SEARCH.view', false) && (
            <Route exact path="/quick-search" component={QuickSearch} />
          )}
          {isMobile && <Route exact path="/account/personal-information" component={PersonalInformation} />}
          {isMobile && <Route exact path="/account/company-profile" component={CompanyInformation} />}
          {isMobile && <Route exact path="/account/change-password" component={ChangePassword} />}
          {get(USER_PERMIT, 'COMPARE.view', false) && <Route exact path="/compare" component={Compare} />}
          {isMobile ? (
            <Route exact path="/account" component={MobileAccount} />
          ) : (
            <Route exact path="/account" component={Account} />
          )}

          {get(USER_PERMIT, 'CART.view', false) &&
            (isMobile ? (
              <Route exact path="/account/cart" component={DiamondList} />
            ) : (
              <Route exact path="/account/cart" component={Account} />
            ))}
          {/* {get(USER_PERMIT, 'HOLD.view', false) && */}
          {isMobile ? (
            <Route exact path="/account/hold" component={DiamondList} />
          ) : (
            <Route exact path="/account/hold" component={Account} />
          )}
          {get(USER_PERMIT, 'WATCHLIST.view', false) &&
            (isMobile ? (
              <Route exact path="/account/watchlist" component={DiamondList} />
            ) : (
              <Route exact path="/account/watchlist" component={Account} />
            ))}
          {get(USER_PERMIT, 'OFFICE.view', false) &&
            (isMobile ? (
              <Route exact path="/account/office-list" component={DiamondList} />
            ) : (
              <Route exact path="/account/office-list" component={Account} />
            ))}
          {get(USER_PERMIT, 'FORMAL_MEET.view', false) &&
            (isMobile ? (
              <Route exact path="/account/formal-meet" component={DiamondList} />
            ) : (
              <Route exact path="/account/formal-meet" component={Account} />
            ))}
          {get(USER_PERMIT, 'NOTES.view', false) &&
            (isMobile ? (
              <Route exact path="/account/notes" component={DiamondList} />
            ) : (
              <Route exact path="/account/notes" component={Account} />
            ))}
          {/* {get(USER_PERMIT, 'QUOTE.view', false) && */}
          {isMobile ? (
            <Route exact path="/account/quote-list" component={Offer} />
          ) : (
            <Route exact path="/account/quote-list" component={Account} />
          )}
          {get(USER_PERMIT, 'CONFIRM_STONE.view', false) &&
            (isMobile ? (
              <Route exact path="/account/confirm-list" component={DiamondList} />
            ) : (
              <Route exact path="/account/confirm-list" component={Account} />
            ))}
          {get(USER_PERMIT, 'ENQUIRY.view', false) && <Route exact path="/account/enquiry" component={Account} />}
          {get(USER_PERMIT, 'SAVE_SEARCH.view', false) && (
            <Route exact path="/account/saved-search" component={Account} />
          )}
          {get(USER_PERMIT, 'DEMAND.view', false) && <Route exact path="/account/my-demand" component={Account} />}
          {get(USER_PERMIT, 'SHIPMENT.view', false) && <Route exact path="/account/my-shipment" component={Account} />}
          {get(USER_PERMIT, 'NOTIFICATIONS.view', false) && (
            <Route exact path="/notifications" component={Notifications} />
          )}
          {get(USER_PERMIT, 'REMINDER.view', false) && <Route exact path="/account/reminder" component={Account} />}

          <Route exact path="/account/profile" component={Account} />

          {/* <Route exact path="/account/profile/manage-user" component={Account} /> */}
          {/* <Route exact path="/account/profile/manage-address" component={Account} /> */}

          {isMobile ? (
            <Route exact path="/account/profile/diamond-sequence" component={DiamondColumnListing} />
          ) : (
            <Route exact path="/account/profile/diamond-sequence" component={Account} />
          )}
          {get(USER_PERMIT, 'MATCH_PAIR.view', false) && <Route exact path="/match-pair" component={DiamondList} />}
          {get(USER_PERMIT, 'UPCOMING.view', false) && <Route exact path="/upcoming" component={DiamondList} />}
          {get(USER_PERMIT, 'EXCLUSIVE.view', false) && <Route exact path="/special-stone" component={DiamondList} />}
          {/* only for show */}
          {get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) && this.props.showData && (
            <Route exact path="/show" component={DiamondList} />
          )}

          {get(USER_PERMIT, 'COLLECTION.view', false) && <Route exact path="/exclusive" component={DiamondList} />}
          {get(USER_PERMIT, 'QUOTE_DAY.view', false) && <Route exact path="/quote-day" component={DiamondList} />}
          {get(USER_PERMIT, 'SEARCH_MATCH_PAIR.view', false) && (
            <Route path="/search-match-pair" component={DiamondSearch} />
          )}
          {get(USER_PERMIT, 'SEARCH_DIAMOND.view', false) && <Route path="/diamond-search" component={DiamondSearch} />}
          {get(USER_PERMIT, 'FANCY_SEARCH.view', false) && <Route path="/fancy-search" component={DiamondSearch} />}
          <Route exact path="/privacy-notice" component={PrivacyNotice} />
          <Route exact path="/track-diamond" component={TrackShipment} />
          {isMobile && <Route exact path="/notification" component={NotificationDropdown} />}
          {get(USER_PERMIT, 'BID.view', false) &&
            (isMobile ? (
              <Route exact path="/search-bid-diamond/:id" component={DiamondSearch} />
            ) : (
              <Route exact path="/search-bid-diamond/:id" component={DiamondSearch} />
            ))}
          {get(USER_PERMIT, 'BID.view', false) &&
            (isMobile ? (
              <Route exact path="/search-bid-diamond" component={DiamondSearch} />
            ) : (
              <Route exact path="/search-bid-diamond" component={DiamondSearch} />
            ))}
          {get(USER_PERMIT, 'BID.view', false) && (
            <Route exact path="/new-arrival-bid-it" component={NewArrivalBidIt} />
          )}
          {get(USER_PERMIT, 'BID.view', false) &&
            (isMobile ? (
              <Route exact path="/deal-of-the-day" component={DealOfDay} />
            ) : (
              <Route exact path="/deal-of-the-day" component={DealOfDay} />
            ))}
          {get(USER_PERMIT, 'MY_BID.view', false) &&
            (isMobile ? (
              <Route exact path="/my-bid" component={NewBidCartList} />
            ) : (
              <Route exact path="/my-bid" component={BidList} />
            ))}
          {get(USER_PERMIT, 'MY_BID.view', false) &&
            (isMobile ? (
              <Route exact path="/my-deal-of-the-day" component={BidDealOfTheDay} />
            ) : (
              <Route exact path="/my-deal-of-the-day" component={BidDealOfTheDay} />
            ))}
          {get(USER_PERMIT, 'SUGGESTED_STOCK.view', false) && (
            <Route exact path="/suggested-stock" component={DiamondList} />
          )}

          {get(USER_PERMIT, 'STONE_OF_THE_DAY.view', false) && (
            <Route exact path="/stone-of-the-day" component={DiamondList} />
          )}

          <Route exact path="/:name(diamond-detail|diamond-details)/:id" component={StoneDetailRoute} />
          <Redirect from="*" to="/not-found" />
        </Switch>
      </MainApp>
    );
  }
}

export default compose(
  connect((store) => ({
    commonData: store.commonData,
    showData: store.auth.showData,
  })),
  withRouter,
)(FrontRoute);
