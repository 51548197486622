import React, { useState, useEffect, useMemo } from 'react';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import { calculate, formatNumber } from './SelectStone';
import {
  handleDownloadExcel,
  getColumn,
  handleConfirmStone,
  getProjectSetting,
  isMobile,
  getPath,
  LISTINGPAGES,
} from '../../DiamondList/DiamondListFunctions';
import Table from '../../DiamondList/TableBack';
import IntlMessages from '../../../util/IntlMessages';
import {
  LOCAL_STORAGE_VAR,
  DIAMOND_BID,
  PROJECT_SETTINGS_TYPE,
  SELECT_STONE_TERMS,
  SELECT_STONE_TERMS_NEW,
} from '../../../constants/Common';
import CheckBox from '../CheckBox';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import SendEmailPopup from './SendEmail';
import OpenNotification from '../CommonButton/OpenNotification';
import SelectOption from '../SelectOption';
import FinalCalcMobile from './FinalCalcMobile';
import { getBidType } from 'components/NewArrivalBidIt';
import { isNaN } from 'lodash';
import { DiamondActions } from 'appRedux/reducers/diamond';

/* eslint jsx-a11y/anchor-is-valid: 0 */

const currentType = 'FinalCalculation';
const FMTERMS = [
  { id: 'COD', name: 'COD Fixed', charge: 1 },
  { id: 'THIRTY', name: '30 Days Fixed', charge: 1.01 },
  { id: 'SIXTY', name: '60 Days Fixed', charge: 1.02 },
];

export function getExtraPer() {
  const terms = localStorage.getItem(`${LOCAL_STORAGE_VAR}-terms`)
    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-terms`))
    : {};
  let extraPer = terms && terms.extraPer ? terms.extraPer : 0;
  // if (extraPer < 0)
  extraPer = extraPer * -1;
  return extraPer;
}

export function newDiamondPrice(diamond, qt = false, field) {
  diamond = { ...diamond };
  if (diamond.isBid) diamond.fmCharge = 0;
  const extraPer = getExtraPer();
  const quotePer = qt === true ? diamond.finalquote * -1 : 0;
  const bidPer = (qt === 'bid' || diamond.isBid) && getBidType() === DIAMOND_BID.TYPE.BLIND ? 0.5 : 0;
  // Changes required here for (+ and -)
  let pricePerCarat =
    qt === 'bid' ? diamond.bidPricePerCarat : qt === true ? diamond.rap - (diamond.rap * quotePer) / 100 : diamond.ctPr;

  if (field == 'fnCtpr') {
    diamond.calcPricePerCarat = parseFloat(diamond.fnCtpr);
    diamond.calcDiscount = (parseFloat(diamond.fnCtpr) * 100) / diamond.rap - 100;
    diamond.calcAmount = parseFloat(diamond.fnCtpr) * diamond.crt;
    return diamond;
  }

  if (field == 'fnAmt') {
    diamond.calcPricePerCarat = parseFloat(diamond.fnAmt) / diamond.crt;
    diamond.calcDiscount = (parseFloat(diamond.calcPricePerCarat) * 100) / diamond.rap - 100;
    diamond.calcAmount = parseFloat(diamond.fnAmt);
    return diamond;
  }

  if (field == 'finalquote') {
    const rate = (1 + diamond.finalquote / 100) * diamond.rap;
    diamond.calcPricePerCarat =
      parseFloat(diamond.rap) + (parseFloat(diamond.finalquote) * parseFloat(diamond.rap)) / 100;
    diamond.calcDiscount = parseFloat(diamond.finalquote);
    diamond.calcAmount = diamond.calcPricePerCarat * diamond.crt;
    return diamond;
  }

  // if(diamond.hasOwnProperty('newDiscount')){
  //   console.log("newDiscount")
  //   diamond.calcPricePerCarat =   (parseFloat(diamond.rap) * ((parseFloat(diamond.newDiscount))/100 )) + parseFloat(diamond.rap);
  //   diamond.calcDiscount = (parseFloat(diamond.newDiscount));
  //   diamond.calcAmount = diamond.newPricePerCarat * diamond.crt;
  // }
  // if (
  //   qt &&
  //   typeof qt === "string" &&
  //   qt.includes("charge") &&
  //   diamond.fmCharge
  // ) {
  //   extraPer = 0;
  //   let selectedFM = qt.slice(6, qt.length);
  //   let newFMamt = pricePerCarat * diamond.crt + diamond.fmCharge;
  //   let FMcharge = newFMamt * find(FMTERMS, { id: selectedFM }).charge;
  //   pricePerCarat = FMcharge / diamond.crt;
  // }
  if (bidPer) pricePerCarat = pricePerCarat - (pricePerCarat * bidPer) / 100;
  // if (qt === true) pricePerCarat = diamond.quote;
  diamond.calcDiscount = diamond.hasOwnProperty('newDiscount') ? diamond.newDiscount : diamond.back;
  diamond.calcPricePerCarat = !diamond.isFcCol
    ? (1 + diamond.calcDiscount / 100) * diamond.rap
    : diamond.hasOwnProperty('newPricePerCarat')
    ? diamond.newPricePerCarat
    : diamond.ctPr;
  //diamond.calcDiscount = (1 - diamond.calcPricePerCarat / diamond.rap) * -100 * -1;
  diamond.calcAmount = diamond.hasOwnProperty('newAmount')
    ? diamond.newAmount
    : diamond.calcPricePerCarat * diamond.crt;
  return diamond;
}

const FinalCalculations = (props) => {
  const term = localStorage.getItem('Terms');
  const [termCal, setTermCal] = useState(term === 'true' ? '' : term);

  const [columns, setColumns] = useState([]);
  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  // const checked = props?.checked || [];
  const [cm, setCm] = useState(false);
  const [fm, setFm] = useState('COD');
  const [settings, setSettings] = useState([]);
  const [email, sendEmail] = useState('');

  const dispatch = useDispatch()

  React.useEffect(()=>{
  return ()=>dispatch(DiamondActions.resetSelectedRows({currentType}));
  },[])

  const getFMCharge = ({ shpNm, crt, clrNm, colNm }) => {
    let charge = 0;
    if (settings.length) {
      const grp = groupBy(settings, 'shp');
      const shaped = shpNm === 'ROUND' ? grp['ROUND'] : grp['FANCY'];
      const carated = shaped.filter((el) => (el.from ? el.from <= crt : true) && (el.to ? el.to >= crt : true));
      if (carated.length) {
        let found = carated.filter((el) => !el.clr && !el.col);
        if (!found.length)
          found = carated.filter(
            (el) =>
              (el.col && el.col.split(',').includes(colNm) ? true : false) &&
              (el.clr && el.clr.split(',').includes(clrNm) ? true : false),
          );
        if (found.length) charge = found[0].charges;
      }
    }
    return charge;
  };

  let newdata = props.checked;
  const fsNow = localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)
    ? JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_VAR}-now`)).split('/')[1]
    : '';
  newdata = newdata.map((n) => ({
    ...n,
    fmCharge: n.isFm === 'ELIG' ? getFMCharge(n) : 0,
    //fmCharge: 0,
    isBid:
      getPath() === LISTINGPAGES.BID ||
      getPath() === LISTINGPAGES.MYBID ||
      (getPath() === 'compare' && (fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID)),
  }));
  newdata = newdata.map((x) => newDiamondPrice(x, 'charge' + fm));
  const isCmVisible = newdata.filter((el) => el.isCm && el.isCm === 'ELIG').length;

  useEffect(() => {
    getProjectSetting(
      props.checked[0].isFm === 'ELIG' ? PROJECT_SETTINGS_TYPE.FM_CHARGES : PROJECT_SETTINGS_TYPE.CM_CHARGES,
      setSettings,
    );
  }, []);

  const getCmCharge = (arr) => {
    let charge = 0;
    arr.map((line) => {
      if (line.isCm && line.isCm === 'ELIG') {
        const found = settings.filter((el) => el.from <= line.crt && (el.to ? el.to >= line.crt : true));
        if (found.length) charge += found[0].fee;
      }
      return true;
    });
    return charge;
  };

  const getTerms = () => {
    const term = localStorage.getItem('Terms');
    return (
      <div>
        <select
          name="termCal"
          value={termCal}
          placeholder="Select Terms"
          onChange={(e) => {
            // let s = calculate(checked, 2)
            setTermCal(e.target.value);
          }}
        >
          {/* <option value='00' disabled>Select Terms</option> */}
          <option value="" disabled selected hidden>
            Select Terms
          </option>
          {SELECT_STONE_TERMS_NEW.map((x) => (
            <option value={x.id}>{x.name}</option>
          ))}
        </select>
      </div>
    );
  };

  const newPricedRows = checked; //.map(x => newDiamondPrice(x, 'charge' + fm))
  // let summation = calculate(newPricedRows);
  const summation = useMemo(() => calculate(newPricedRows, termCal), [checked, termCal]);
  const totalPieces = summation.total_pieces;
  const totalCarat = parseFloat(summation.total_carat).toFixed(2);
  const rapPrice = summation.final_rapaport;
  const avgDisc = parseFloat(summation.final_discount).toFixed(2);
  const avgPr = summation.final_price;
  const finalValue = parseFloat(summation.final_net_value).toFixed(2);
  const promotionalDis = props?.promotionDis?.data;
  const showDis = props?.showDis?.data;
  const maxAmt = Math.round((parseFloat(props.promotionDis?.maxAmt) * promotionalDis) / 100);
  const netValue = formatNumber(parseFloat(summation.final_value).toFixed(2));

  const addDis = parseFloat(summation.addDis).toFixed(2);
  const sysDis = summation.sysDis;
  const finalNetRate = formatNumber(parseFloat(summation.final_rate).toFixed(2));
  const sysAmtDisc = Number(summation.final_value) * (Number(summation.sysDis) / 100);
  const finalAvgDisc = parseFloat(Number(summation.final_back)).toFixed(2);
  const sysDis_with_positive = summation.sysDis_with_positive;
  const finalNetValue = formatNumber(parseFloat(summation.final_net_value).toFixed(2));
  const CmCharge = parseFloat(getCmCharge(newPricedRows)).toFixed(2);
  const terms = getTerms();
  const fancyDiam = localStorage.getItem('fancyDiamond');
  // const profinalNetValue =
  //   parseFloat(finalValue) > props.promotionDis?.maxAmt
  //     ? parseFloat(finalValue) + maxAmt
  //     : parseFloat(
  //         parseFloat(summation.final_net_value) + parseFloat(summation.final_net_value) * (promotionalDis / 100),
  //       ).toFixed(2);
  const promDiscAmount = parseFloat(Math.abs((finalValue * promotionalDis) / 100).toFixed(2));
  const showDiscAmount = parseFloat(Math.abs((finalValue * showDis) / 100).toFixed(2));

  // const profinalNetValue =
  //   props?.location?.pathname === '/show' && showDiscAmount > props.showDis?.maxAmt
  //     ? parseFloat(finalValue) - props.showDis?.maxAmt
  //     : showDiscAmount < props.showDis?.maxAmt
  //     ? parseFloat(finalValue) - showDiscAmount
  //     : promDiscAmount > props.promotionDis?.maxAmt
  //     ? parseFloat(finalValue) - props.promotionDis?.maxAmt
  //     : parseFloat(finalValue) - promDiscAmount;

  let profinalNetValue;

  if (props?.location?.pathname === '/show' && props?.showDis?.data !== 0) {
    if (showDiscAmount > props.showDis?.maxAmt) {
      profinalNetValue = parseFloat(finalValue) - props.showDis?.maxAmt;
    } else {
      profinalNetValue = parseFloat(finalValue) - showDiscAmount;
    }
  } else {
    if (promDiscAmount > props.promotionDis?.maxAmt) {
      profinalNetValue = parseFloat(finalValue) - props.promotionDis?.maxAmt;
    } else {
      profinalNetValue = parseFloat(finalValue) - promDiscAmount;
    }
  }

  const profinalNetCtPr = parseFloat(parseFloat(profinalNetValue) / parseFloat(totalCarat)).toFixed(2);

    const getTotalNetAmount = (data) => {
    let totalNetAmount = 0;
    if (data && data?.length > 0) {
      const res = data?.forEach((cell) => {
        const amnt = cell.amt;
        const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
        const amntPer = (amnt * sysDis_with_positive) / 100;
        const amntafterSysDis = amnt - amntPer;
        const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

        const netAmountAfterTermDisc =
          termDisFromAmtafterSyaDis < 0
            ? amntafterSysDis + termDisFromAmtafterSyaDis
            : amntafterSysDis - termDisFromAmtafterSyaDis;

        totalNetAmount += netAmountAfterTermDisc;
        return;
      });
    }
    return totalNetAmount;
  };

  useEffect(() => {
    const Columns = getColumn();
    let index = findIndex(Columns, { id: 'rap' });
    if (!index) index = 1;
    const columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      // find(Columns, { id: 'msrmnt' }),
      props.checked[0].isFm === 'ELIG' && !(getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MYBID)
        ? {
            Header: 'FM Charges',
            accessor: 'fmCharge',
            id: 'fmCharge',
            Cell: ({ cell }) => <>{parseFloat(cell.value).toFixed(2)}</>,
          }
        : null,
      {
        Header: 'Net Discount',
        accessor: 'netDis',
        id: 'netDis',
        Cell: ({ cell }) => {
          const amnt = cell.row.original.amt;
          const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
          const amntPer = (amnt * sysDis_with_positive) / 100;
          const amntafterSysDis = amnt - amntPer;
          const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

          const netAmountAfterTermDisc =
            termDisFromAmtafterSyaDis < 0
              ? amntafterSysDis + termDisFromAmtafterSyaDis
              : amntafterSysDis - termDisFromAmtafterSyaDis;

          const promDisc =
            props.promotionDis?.data !== 0
              ? Math.abs((netAmountAfterTermDisc * props?.promotionDis?.data) / 100)
              : props.showDis?.data !== 0
              ? Math.abs((netAmountAfterTermDisc * props?.showDis?.data) / 100)
              : null;

          const totalNetAmount = getTotalNetAmount(props?.checked);
          let netAmount;

          if (props?.location?.pathname === '/show' && props?.showDis?.data !== 0) {
            if (showDiscAmount > props.showDis?.maxAmt) {
               if (props.diamondData?.selectedRows['FinalCalculation']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.showDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.showDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - showDiscAmount;
            }
          } else if( props.promotionDis?.data !== 0) {
            if (promDisc > props.promotionDis?.maxAmt ) {
              if (props.diamondData?.selectedRows['FinalCalculation']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.promotionDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.promotionDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
              // netAmount = parseFloat(netAmountAfterTermDisc) - props.promotionDis?.maxAmt;
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - promDisc;
            }
          } else {
            netAmount = netAmountAfterTermDisc
          }
          const netPriceperCarat = netAmount / cell.row.original.crt;
          const netPriceCrtRap = netPriceperCarat / cell.row.original.rap;
          const netDiscount = netPriceCrtRap > 1 ? -(1 - netPriceCrtRap) * 100 : (1 - netPriceCrtRap) * 100;

          return (
            <>
              {isNaN(netDiscount) ? '0.00' : `${netPriceCrtRap > 1 ? '+' : '-'} ${parseFloat(netDiscount).toFixed(2)}`}
            </>
          );
        },
      },
      {
        Header: 'Net Price/ct',
        accessor: 'netPriceCt',
        id: 'netPriceCt',
        Cell: ({ cell }) => {
          const amnt = cell.row.original.amt;
          const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
          const amntPer = (amnt * sysDis_with_positive) / 100;
          const amntafterSysDis = amnt - amntPer;
          const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

          const netAmountAfterTermDisc =
            termDisFromAmtafterSyaDis < 0
              ? amntafterSysDis + termDisFromAmtafterSyaDis
              : amntafterSysDis - termDisFromAmtafterSyaDis;

          const promDisc =
            props.promotionDis?.data !== 0
              ? Math.abs((netAmountAfterTermDisc * props?.promotionDis?.data) / 100)
              : props.showDis?.data !== 0
              ? Math.abs((netAmountAfterTermDisc * props?.showDis?.data) / 100)
              : 0;

          const totalNetAmount = getTotalNetAmount(props?.checked);
          let netAmount;

          if (props?.location?.pathname === '/show' && props?.showDis?.data !== 0) {
            if (showDiscAmount > props.showDis?.maxAmt) {
               if (props.diamondData?.selectedRows['FinalCalculation']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.showDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.showDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - showDiscAmount;
            }
          } else if(props.promotionDis?.data !== 0) {
            if (promDisc > props.promotionDis?.maxAmt) {
               if (props.diamondData?.selectedRows['FinalCalculation']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.promotionDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.promotionDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - promDisc;
            }
          } else {
            // console.log("else")
            netAmount = netAmountAfterTermDisc;
            // console.log(netAmount)
          }
          const netPriceperCarat = netAmount / cell.row.original.crt;

          return <>{isNaN(netPriceperCarat) ? '0.00' : parseFloat(netPriceperCarat).toFixed(2)}</>;
        },
      },
      {
        Header: 'Net Amount',
        accessor: 'netAmo',
        id: 'netAmo',
        Cell: ({ cell }) => {
          const amnt = cell.row.original.amt;
          const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
          const amntPer = (amnt * sysDis_with_positive) / 100;
          const amntafterSysDis = amnt - amntPer;
          const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

          const netAmountAfterTermDisc =
            termDisFromAmtafterSyaDis < 0
              ? amntafterSysDis + termDisFromAmtafterSyaDis
              : amntafterSysDis - termDisFromAmtafterSyaDis;

          const promDisc =
            props.promotionDis?.data !== 0
              ? Math.abs((netAmountAfterTermDisc * props?.promotionDis?.data) / 100)
              : props.showDis?.data !== 0
              ? Math.abs((netAmountAfterTermDisc * props?.showDis?.data) / 100)
              : null;

          const totalNetAmount = getTotalNetAmount(props?.checked);
          let netAmount;

          if (props?.location?.pathname === '/show' && props?.showDis?.data !== 0) {
            if (showDiscAmount > props.showDis?.maxAmt) {
              if (props.diamondData?.selectedRows['FinalCalculation']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.showDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.showDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - showDiscAmount;
            }
          } else if( props.promotionDis?.data !== 0) {
            if (promDisc > props.promotionDis?.maxAmt ) {
               if (props.diamondData?.selectedRows['FinalCalculation']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.promotionDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.promotionDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - promDisc;
            }
          } else {
            netAmount = netAmountAfterTermDisc
          }
          // console.log(netAmount)

          // const netAmount= 0;
          return <>{isNaN(netAmount) ? '0.00' : parseFloat(netAmount).toFixed(2)}</>;
        },
      },
      // {
      //   Header: "Final Disc",
      //   // "Final Disc(" +
      //   // parseFloat(getExtraPer()).toFixed(2) +
      //   // ((getPath() === LISTINGPAGES.BID ||
      //   //   getPath() === LISTINGPAGES.MYBID ||
      //   //   (getPath() === "compare" &&
      //   //     (fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID))) &&
      //   //   getBidType() === DIAMOND_BID.TYPE.BLIND
      //   //   ? "+0.5"
      //   //   : "") +
      //   // "%)",
      //   accessor: "fnBack",
      //   id: "fnBack",
      //   Cell: ({ row }) => (
      //     <span className="themeColor">{parseFloat(row.original.calcDiscount).toFixed(2)}</span>
      //   ),
      // },
      // {
      //   Header: "Final Rate",
      //   accessor: "fnCtpr",
      //   id: "fnCtpr",
      //   Cell: ({ row }) => (
      //     <span className="themeColor">
      //       {parseFloat(row.original.calcPricePerCarat).toFixed(2)}
      //     </span>
      //   ),
      // },
      // {
      //   Header: "Final Value",
      //   accessor: "fnAmt",
      //   id: "fnAmt",
      //   Cell: ({ row }) => (
      //     <span className="themeColor">{parseFloat(row.original.calcAmount).toFixed(2)}</span>
      //   ),
      // },
      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ];
    setColumns(columns.filter((el) => el && el));
  }, [addDis, sysDis_with_positive]);
  let promoDis = null;

  if (props.currentType === '/show' && props.showDis.data !== 0) {
    promoDis = parseFloat(props?.showDis?.data);
  } else {
    promoDis = parseFloat(props.promotionDis?.data);
  }

  const VALUES = isMobile()
    ? {
        'Total Pieces': totalPieces,
        'Total Carats:': totalCarat,
        'Rap Prices': rapPrice,
        'Avg. Discount(%)': <b>{avgDisc}</b>,
        'Total Pr/Ct': avgPr,
        'Net Value': netValue,
        'Amt Discount(%)': <b>{sysDis}</b>,
        'Terms Discount(%)':
          Number(addDis) <= 0 ? (
            <div className={`${addDis === '0.00' ? '' : 'bold-text'}`}>{addDis}</div>
          ) : (
            `+${addDis}`
          ),
        'Final Discount(%)':
          (props?.currentType === '/show' &&
            parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'NaN') ||
          parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'NaN' ||
          parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'Infinity' ? (
            '0.00'
          ) : props.promotionDis?.data !== 0 || props.showDis?.data !== 0 ? (
            <b>{parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2)}</b>
          ) : (
            <b>{finalAvgDisc}</b>
          ),
        'Promotional Dis(%)': <b>{promoDis}</b>,
        'Final Net Rate':
          (props.currentType === '/show' && formatNumber(profinalNetCtPr) === 'NaN' && finalNetRate === 'NaN') ||
          (formatNumber(profinalNetCtPr) === 'NaN' && finalNetRate === 'NaN')
            ? '0.00'
            : props.promotionDis?.data || props.showDis?.data
            ? parseFloat(parseFloat(profinalNetValue) / parseFloat(totalCarat)).toFixed(2)
            : finalNetRate,
        'Final Net Value':
          formatNumber(profinalNetValue) === NaN || finalNetValue === 'NaN'
            ? '0.00'
            : props.promotionDis?.data !== 0 || props.showDis?.data !== 0
            ? formatNumber(profinalNetValue)
            : finalNetValue,
        TERMS: terms,
      }
    : [
        // { title: 'Total Pieces', value: totalPieces },
        // { title: 'Total Carats', value: totalCarat },
        // { title: 'Rap Prices', value: rapPrice },
        // { title: 'Avg. Discount(%)', value: <b>{avgDisc}</b> },
        // { title: 'Total Pr/Ct', value: avgPr },

        // { title: 'Net Value', value: netValue },

        // { title: 'Amt Discount(%)', value: <b>{sysDis}</b> },
        // {
        //   title: 'Terms Discount(%)',
        //   value:
        //     Number(addDis) <= 0 ? (
        //       <div className={`${addDis === '0.00' ? '' : 'bold-text'}`}>{addDis}</div>
        //     ) : (
        //       `+${addDis}`
        //     ),
        // },
        // {
        //   title: 'Final Discount(%)',
        //   value: (
        //     <b>
        //       {props.promotionDis?.data
        //         ? parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2)
        //         : finalAvgDisc}{' '}
        //     </b>
        //   ),
        // },
        // props.promotionDis.data !== 0 && { title: 'promotional Dis(%)', value: <b>{props.promotionDis?.data}</b> },
        // { title: 'Final Net Rate', value: props.promotionDis?.data ? formatNumber(profinalNetCtPr) : finalNetRate },
        // {
        //   title: 'Final Net Value',
        //   value: props.promotionDis?.data ? formatNumber(profinalNetValue) : finalNetValue,
        // },
        // {
        //   title: 'TERMS',
        //   value: terms,
        // },
        { title: 'Total Pieces', value: totalPieces === undefined ? 0.0 : totalPieces },
        { title: 'Amt Discount(%)', value: sysDis === undefined ? '0.00' : <b>{sysDis}</b> },
        { title: 'Rap Prices', value: rapPrice || '0.00' },
        {
          title: 'Terms Discount(%)',
          value:
            addDis === 'NaN' ? (
              '0.00'
            ) : Number(addDis) <= 0 ? (
              <div className={`${addDis === '0.00' ? '' : 'bold-text'}`}>{addDis}</div>
            ) : (
              `+${addDis}`
            ),
        },
        { title: 'Avg. Discount(%)', value: avgDisc === 'NaN' ? '0.00' : <b>{avgDisc}</b> },

        {
          title: 'Final Discount(%)',
          value:
            (props?.currentType === '/show' &&
              parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'NaN') ||
            parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'NaN' ||
            parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'Infinity' ? (
              '0.00'
            ) : props.promotionDis?.data !== 0 || props.showDis?.data !== 0 ? (
              <b>{parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2)}</b>
            ) : (
              <b>{finalAvgDisc}</b>
            ),
        },
        { title: 'Total Pr/Ct', value: avgPr || '0.00' },
        {
          title: 'Final Net Rate',
          value:
            (props.currentType === '/show' && formatNumber(profinalNetCtPr) === 'NaN' && finalNetRate === 'NaN') ||
            (formatNumber(profinalNetCtPr) === 'NaN' && finalNetRate === 'NaN')
              ? '0.00'
              : props.promotionDis?.data || props.showDis?.data
              ? // ? formatNumber(profinalNetCtPr)
                parseFloat(parseFloat(profinalNetValue) / parseFloat(totalCarat)).toFixed(2)
              : finalNetRate,
        },
        { title: 'Total Carats', value: totalCarat === 'NaN' ? '0.00' : totalCarat },
        {
          title: 'Final Net Value',
          value:
            formatNumber(profinalNetValue) === NaN || finalNetValue === 'NaN'
              ? '0.00'
              : props.promotionDis?.data !== 0 || props.showDis?.data !== 0
              ? formatNumber(profinalNetValue)
              : finalNetValue,
        },
        { title: 'Net Value', value: netValue === 'NaN' ? '0.00' : netValue },
        props.location?.pathname === '/show' && props.showDis.data !== 0
        ? { title: 'promotional Dis(%)', value: promoDis }
        : props?.promotionDis?.data !== 0 && props.location?.pathname !== '/show'
        ? { title: 'promotional Dis(%)', value: promoDis }
        : { title: 'promo. Discount(%)', value: '0.00' },
        {
          title: 'TERMS',
          value: terms,
        },
      ];

  const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to confirm.',
      });
      return false;
    } else return true;
  };
  const submit = () => {
    if (!checkCheck()) return;
    handleConfirmStone(
      checked.map((x) => x.id),
      '',
      moment().toISOString(),
      user && user.account ? user.account.companyName : '',
      (flag) => {
        props.onClose();
        props.clearAll();
        if (flag && props.fetch) props.fetch();
      },
    );
  };
  const confirmStonePopupCounter = localStorage.getItem('confirmStonePopupCounter');

  const finalStonePopupCounter = localStorage.getItem('finalStonePopupCounter');
  return isMobile() ? (
    <FinalCalcMobile
      data={newdata}
      VALUES={VALUES}
      currentType={currentType}
      parent={{ submit, checkCheck, onClose: props.onClose, termCal }}
      noConfirmButton={props.noConfirmButton}
      promotionDis={props?.promotionDis}
      showDis={props?.showDis}
      diamondData={props?.diamondData && {...props?.diamondData}}
      location = {props?.location && {...props?.location}}
    />
  ) : (
    <div>
      <h2 className="popupInnerTitle">
        <IntlMessages id="app.FinalCalculation" />
      </h2>
      <div className="searchPopupCommonTable">
        <div className="searchResultTable tabInnerTableScroll">
          <Table
            data={newdata}
            columns={columns}
            areAllChecked={confirmStonePopupCounter <= 0 ? true : false}
            // areAllChecked={finalStonePopupCounter === 1 ? true : finalStonePopupCounter === 1 && props?.diamondData?.selectedRows["/diamond-list"]?.length === props?.diamondData?.selectedRows[currentType]?.length ? true:  false }
            nodots
            noGrp
            currentType={currentType}
            canSort={false}
          />
        </div>
        <div className="mt-10 finalCalDetail">
          {props.checked[0].isFm === 'ELIG' && !(getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MYBID) ? (
            <SelectOption
              className="finalFMSelect"
              selectValue={FMTERMS}
              label="FM Terms*"
              value={fm}
              onChange={(e) => setFm(e)}
            />
          ) : isCmVisible ? (
            <CheckBox label="CM Charge" checked={cm} onChange={() => setCm(!cm)} />
          ) : null}
          <div className="DiamondDetailPopup mt-10">
            {/* {VALUES.map((x, key) => (
              <div key={key} className="DiamondDetailPopupItem">
                <span>{x.title}</span>
                <span>
                  {x.title === 'Avg. Discount(%)' || x.title === 'Final Discount(%)'
                    ? x.value > 0
                      ? `+${x.value}`
                      : x.value
                    : x.title === 'promotional Dis(%)'
                    ? // ? parseFloat(finalValue) > props.promotionDis?.maxAmt
                      //   ? `${x.value} ($${Math.abs(
                      //       Math.round((parseFloat(props.promotionDis?.maxAmt) * promotionalDis) / 100),
                      //     )})`
                      //   : `${x.value} ($${Math.abs(promotionalDis)})`
                      promDiscAmount > props.promotionDis?.maxAmt
                      ? `${x.value} ($${Math.abs(parseFloat(props.promotionDis?.maxAmt).toFixed(2))})`
                      : `${x.value} ($${parseFloat(promDiscAmount)})`
                    : x.value}
                </span>
              </div>
            ))} */}
            {VALUES.map((x) => {
              let valueToRender;

              if (x.title === 'Avg. Discount(%)' || x.title === 'Final Discount(%)') {
                if (x.value > 0) {
                  valueToRender = `+${x.value}`;
                } else {
                  valueToRender = x.value;
                }
              } else if (x.title === 'promotional Dis(%)') {
                if (props.location.pathname === '/show') {
                  if (showDiscAmount > props.showDis?.maxAmt) {
                    valueToRender = `${x.value} ($${Math.abs(parseFloat(props.showDis?.maxAmt).toFixed(2))})`;
                  } else if (!isNaN(showDiscAmount)) {
                    valueToRender = `${x.value} ($${parseFloat(showDiscAmount)})`;
                  } else {
                    valueToRender = `${x.value} ($0.00)`;
                  }
                } else {
                  if (props.promotionDis?.data !== 0) {
                    if (promDiscAmount > props.promotionDis?.maxAmt) {
                      valueToRender = `${x.value} ($${Math.abs(parseFloat(props.promotionDis?.maxAmt).toFixed(2))})`;
                    } else if (!isNaN(promDiscAmount)) {
                      valueToRender = `${x.value} ($${parseFloat(promDiscAmount)})`;
                    } else {
                      valueToRender = `${x.value} ($0.00)`;
                    }
                  }
                }
              } else {
                valueToRender = x.value;
              }
              if(!Number(x.value) && x.title === 'promo. Discount(%)') return <></>
              if (x.title === 'TERMS') {
                return (
                  <div className="DiamondDetailPopupItem" key={x.title} style={{ width: '100%' }}>
                    <span>{x.title}</span>
                    <span>{valueToRender}</span>
                  </div>
                );
              }
              return (
                <div className="DiamondDetailPopupItem" key={x.title}>
                  <span>{x.title}</span>
                  <span>{valueToRender}</span>
                </div>
              );
            })}
          </div>

          {props.location?.pathname === '/show' &&
          ((props.promotionDis?.data && props.promotionDis?.maxAmt) ||
            (props.showDis?.data && props.showDis?.maxAmt)) ? (
            <p className="showNote">
              <b>
                <IntlMessages id="app.Note" />:
              </b>

              <IntlMessages id="app.showStoneNote" />
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="sideBarPopupButton">
          {/* {!props.noConfirmButton && (
                        <a className='commonButton' onClick={submit}>
                            <IntlMessages id='app.confirmStone' />
                        </a>
                    )} */}
          <a className="commonButton" onClick={props.onClose}>
            <IntlMessages id="app.CancelStone" />
          </a>
          <a
            className="commonButton"
            onClick={() => {
              if (!termCal) {
                OpenNotification({
                  type: 'error',
                  title: 'You need to select term before excel export',
                });
              } else {
                handleDownloadExcel(
                  {},
                  checked.map((x) => x.id),
                  () => {},
                );
              }
            }}
          >
            <IntlMessages id="app.ExcelExport" />
          </a>
          <a
            className="commonButton"
            // disabled={!termCal}
            onClick={() => {
              if (!checked.length) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
              } else if (!termCal) {
                OpenNotification({
                  type: 'error',
                  title: 'You need to select term before email stock',
                });
              } else {
                sendEmail('stock');
              }
            }}
          >
            <IntlMessages id="app.EmailStock" />
          </a>
          <a
            // disabled={!termCal}
            className="commonButton"
            onClick={() => {
              if (!checked.length) {
                OpenNotification({
                  type: 'error',
                  title: 'Please select any stone(s).',
                });
              } else if (!termCal) {
                OpenNotification({
                  type: 'error',
                  title: 'You need to select term before email pic',
                });
              } else {
                sendEmail('img');
              }
            }}
          >
            <IntlMessages id="app.EmailPic" />
          </a>
        </div>
      </div>
      {email !== '' && (
        <SendEmailPopup
          sendEmail={email}
          onCancel={() => sendEmail('')}
          ids={checked.map((x) => x.id)}
          img={email === 'img'}
          isStock={email === 'stock'}
        />
      )}
    </div>
  );
};

export default FinalCalculations;
