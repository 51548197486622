import React from 'react';
import { useSelector } from 'react-redux';
import { store } from 'appRedux/store';

import { IntlMessage } from 'util/IntlMessages';
import { getPath, LISTINGPAGES, isMobile } from 'components/DiamondList/DiamondListFunctions';

import { SELECT_STONE_TERMS_NEW, LOCAL_STORAGE_VAR } from 'constants/Common';
import { formatDecimal, isArray, isEmpty } from 'util/utils';
import { useIsMobile } from 'util/hooks';
import { calcTotal } from 'util/total.calc';

export const formatNumber = formatDecimal;
export const calculate = (stoneList = [], term = 0) => {
  const calc = calcTotal(stoneList, { term: SELECT_STONE_TERMS_NEW.find(({ id }) => id === term) ?? 0 });
  return {
    total_pieces: calc?.count,
    total_carat: calc?.sumCrt,
    total_rapaport: calc?.sumRap,
    final_rapaport: calc?.avgRapByCrt,
    final_discount: calc?.avgBack,
    final_price: calc?.sumCtPr,
    final_value: calc?.sumAmt,
    addDis: calc?.termDis,
    sysDis_with_positive: calc?.sysDis_with_positive,
    sysDis: calc?.sysDis,
    final_term_discount: calc?.sysBack,
    final_rate: calc?.finalCtPr,
    final_net_value: calc?.finalAmt,
    final_avg_disc: calc?.finalAvgDisc,
    bid_final_disc: calc?.bidFinalDisc,
    bid_final_Pr: calc?.bidFinalPr,
    bid_final_amt: calc?.bidFinalAmt,
    term_sys_dis: calc?.termSysDis,
    avg_back: calc?.avgBack,
    final_back: calc?.finalBack,
    offerFinalAmt: calc?.offerFinalAmt,
    offerFinalPr: calc?.offerFinalPr,
    offerFinalDisc: calc?.offerFinalDisc,
  };
};

const StoneTotal = (props) => {
  const { currentType, sum, profilListing, blockStyle } = props;

  const [term, setTerm] = React.useState();
  // const [isMobile] = useIsMobile();

  const selectedRows = useSelector((state) => state.diamondData?.selectedRows?.[currentType] ?? []);
  const clearSelection = React.useCallback(() => {
    if (document.getElementsByTagName('table')[0]) {
      store.dispatch({ type: 'RESET_SELECTED_ROWS' });
      const tblRows = document.getElementsByTagName('table')[0].rows;
      for (const row of tblRows) row.classList.remove('selectTr');
    } else {
      store.dispatch({ type: 'RESET_SELECTED_ROWS' });
    }
  }, []);

  const content = React.useMemo(() => {
    const guest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);

    if (isEmpty(selectedRows)) return [];
    const isUpcoming = Boolean(getPath() === LISTINGPAGES.UPCOMING);
    const total = {
      count: sum?.count ?? 0,
      sumCrt: sum?.totalCarat ?? 0,
      avgRapByCrt: sum?.rapPrice ?? 0,
      avgBack: sum?.avgDiscount ?? 0,
      sumCtPr: sum?.totalPricePerCarat ?? 0,
      sumAmt: sum?.totalAmount ?? 0,
      finalBack: sum?.avgDiscount ?? 0,
      finalCtPr: sum?.totalPricePerCarat ?? 0,
      finalAmt: sum?.totalAmount ?? 0,
    };
    // Filtering the selected data

    const filteredData = selectedRows.filter((item) => item.wSts !== 'U');

    const calc = calcTotal(selectedRows, {
      term: SELECT_STONE_TERMS_NEW.find(({ id }) => id === term) ?? 0,
    });

    const promDiscAmount = parseFloat(Math.abs((calc.finalAmt * props.promotionDis?.data) / 100).toFixed(2));
    const showDiscAmount = parseFloat(Math.abs((calc.finalAmt * props.showDis?.data) / 100).toFixed(2));

    const termDisc = Number(calc.termDis) <= 0 ? calc.termDis : `${calc.termDis}`;
    localStorage.setItem('TermsDis', termDisc);
    const maxAmt = Math.round((parseFloat(props.promotionDis?.maxAmt) * props.promotionDis?.data) / 100);
    // const profinalNetValue =
    //   promDiscAmount > props.promotionDis?.maxAmt
    //     ? // ? parseFloat(calc.finalAmt) + maxAmt
    //       // : parseFloat(parseFloat(calc.finalAmt) + parseFloat(calc.finalAmt) * (props.promotionDis?.data / 100)).toFixed(
    //       //     2,
    //       //   );
    //       parseFloat(calc.finalAmt) - props.promotionDis?.maxAmt
    //     : parseFloat(calc.finalAmt) - promDiscAmount;

    let profinalNetValue;

    if (props?.currentType === '/show' && props?.showDis?.data !== 0) {
      if (showDiscAmount > props.showDis?.maxAmt) {
        profinalNetValue = parseFloat(calc.finalAmt) - props.showDis?.maxAmt;
      } else {
        profinalNetValue = parseFloat(calc.finalAmt) - showDiscAmount;
      }
    } else if(props?.promotionDis?.data !== 0){
      if (promDiscAmount > props.promotionDis?.maxAmt) {
        profinalNetValue = parseFloat(calc.finalAmt) - props.promotionDis?.maxAmt;
      } else {
        profinalNetValue = parseFloat(calc.finalAmt) - promDiscAmount;
      }
    }else{
      profinalNetValue = parseFloat(calc.finalAmt)
    }

    const profinalNetCtPr = parseFloat(parseFloat(profinalNetValue) / parseFloat(calc.sumCrt)).toFixed(2);

    const common = [
      { label: 'Pieces', check: Number(calc.count), sum: Number(total.count) },
      { label: 'Carat', check: formatDecimal(calc.sumCrt), sum: formatDecimal(total.sumCrt) },
    ];
    if (term === undefined) {
      localStorage.setItem('Terms', true);
    } else {
      localStorage.setItem('Terms', term);
    }
    if (isUpcoming) return common;

    if (guest) {
      return [
        ...common,
        { label: 'Rap Price', check: formatDecimal('-'), sum: formatDecimal('-') },
        { label: 'Avg. Dis(%)', check: formatDecimal('-'), sum: formatDecimal('-') },
        { label: 'Total Pr/Ct', check: formatDecimal('-'), sum: formatDecimal('-') },
        getPath() !== LISTINGPAGES.BID || getPath() !== LISTINGPAGES.MYBID || getPath() !== LISTINGPAGES.DEALTHEOFDAY
          ? { label: <IntlMessage id="app.Amount" />, check: formatDecimal('-'), sum: formatDecimal('-') }
          : {
              label: <IntlMessage id="app.Amount" />,
              check: formatDecimal(calc.bidFinalAmt),
              sum: formatDecimal(total.sumAmt),
            },
        getPath() !== LISTINGPAGES.BID ||
          getPath() !== LISTINGPAGES.MYBID ||
          (getPath() !== LISTINGPAGES.DEALTHEOFDAY && { label: 'Amt Dis(%)', check: formatDecimal('-') }),
        getPath() !== LISTINGPAGES.BID &&
          getPath() !== LISTINGPAGES.MYBID &&
          getPath() !== LISTINGPAGES.DEALTHEOFDAY && {
            label: 'Term Dis(%)',
            check: Number(calc.termDis) <= 0 ? calc.termDis : `+${calc.termDis}`,
            sum: formatDecimal(total.termDis),
          },
        // getPath() !== LISTINGPAGES.BID && getPath() !== LISTINGPAGES.MYBID
        //   ? { label: 'Final Dis(%)', check: formatDecimal('-'), sum: formatDecimal('-') }
        //   : {
        //       label: 'Final Dis(%)',
        //       check: formatDecimal(calc.bidFinalDisc),
        //       sum: formatDecimal(total.bid_final_disc),
        //     },
        // getPath() !== LISTINGPAGES.BID && getPath() !== LISTINGPAGES.MYBID
        //   ? { label: 'Final Pr/Ct', check: formatDecimal('-'), sum: formatDecimal('-') }
        //   : { label: 'Final Pr/Ct', check: formatDecimal(calc.bidFinalPr), sum: formatDecimal(total.bid_final_Pr) },
        // getPath() !== LISTINGPAGES.BID && getPath() !== LISTINGPAGES.MYBID
        //   ? { label: 'Final Amount', check: formatDecimal('-'), sum: formatDecimal('-') }
        //   : { label: 'Final Amount', check: formatDecimal(calc.bidFinalAmt), sum: formatDecimal(total.bid_final_amt) },
        getPath() !== LISTINGPAGES.BID &&
          getPath() !== LISTINGPAGES.MYBID &&
          getPath() !== LISTINGPAGES.DEALTHEOFDAY && {
            label: 'Final Dis(%)',
            check: formatDecimal('-'),
            sum: formatDecimal('-'),
          },
        getPath() !== LISTINGPAGES.BID &&
          getPath() !== LISTINGPAGES.MYBID &&
          getPath() !== LISTINGPAGES.DEALTHEOFDAY && {
            label: 'Final Pr/Ct',
            check: formatDecimal('-'),
            sum: formatDecimal('-'),
          },
        getPath() !== LISTINGPAGES.BID ||
          getPath() !== LISTINGPAGES.MYBID ||
          (getPath() !== LISTINGPAGES.DEALTHEOFDAY && {
            label: 'Final Amount',
            check: formatDecimal('-'),
            sum: formatDecimal('-'),
          }),
      ];
    }

    let promotionalDisCheck = '';

    if (props.currentType === '/show') {
      if (showDiscAmount > props.showDis?.maxAmt) {
        promotionalDisCheck = `${props.showDis?.data} ($${props.showDis?.maxAmt})`;
      } else {
        promotionalDisCheck = `${props.showDis?.data} ($${parseFloat(showDiscAmount)})`;
      }
    } else {
      if (promDiscAmount > props.promotionDis?.maxAmt) {
        promotionalDisCheck = `${props.promotionDis?.data} ($${props.promotionDis?.maxAmt})`;
      } else {
        promotionalDisCheck = `${props.promotionDis?.data} ($${parseFloat(promDiscAmount)})`;
      }
    }


    return [
      ...common,
      { label: 'Rap Price', check: formatDecimal(calc.avgRapByCrt), sum: formatDecimal(total.avgRapByCrt) },
      { label: 'Avg. Dis(%)', check: formatDecimal(calc.avgBack), sum: formatDecimal(total.avg_back) },
      { label: 'Total Pr/Ct', check: formatDecimal(calc.sumCtPr), sum: formatDecimal(total.sumCtPr) },
      getPath() !== LISTINGPAGES.BID || getPath() !== LISTINGPAGES.MYBID
        ? {
            label: <IntlMessage id="app.Amount" />,
            check: formatDecimal(calc.sumAmt),
            sum: formatDecimal(total.sumAmt),
          }
        : {
            label: <IntlMessage id="app.Amount" />,
            check: formatDecimal(calc.bidFinalAmt),
            sum: formatDecimal(total.sumAmt),
          },
      getPath() !== LISTINGPAGES.BID &&
        getPath() !== LISTINGPAGES.MYBID &&
        getPath() !== LISTINGPAGES.DEALTHEOFDAY &&
        getPath() !== LISTINGPAGES.MYDEALOFDAYLIST && { label: 'Amt Dis(%)', check: formatDecimal(calc.sysDis) },
      getPath() !== LISTINGPAGES.BID &&
        getPath() !== LISTINGPAGES.MYBID &&
        getPath() !== LISTINGPAGES.DEALTHEOFDAY &&
        getPath() !== LISTINGPAGES.MYDEALOFDAYLIST && {
          label: 'Term Dis(%)',
          check: Number(calc.termDis) <= 0 ? calc.termDis : `+${calc.termDis}`,
          sum: formatDecimal(total.termDis),
        },
      getPath() !== LISTINGPAGES.BID &&
        getPath() !== LISTINGPAGES.MYBID &&
        getPath() !== LISTINGPAGES.DEALTHEOFDAY &&
        getPath() !== LISTINGPAGES.MYDEALOFDAYLIST &&
        (props.promotionDis?.data !== 0 || (props.currentType === '/show' && props.showDis?.data !== 0)) && {
          label: 'Promotional Dis(%)',
          check: promotionalDisCheck,
          // props?.location?.pathname === '/show' && showDiscAmount > props.showDis?.maxAmt
          //   ? ` ${props.showDis?.data} ($${props.showDis?.maxAmt})`
          //   : showDiscAmount < props.showDis?.maxAmt
          //   ? `${props.showDis?.data} ($${parseFloat(showDiscAmount)})`
          //   : promDiscAmount > props.promotionDis?.maxAmt
          //   ? `${props.promotionDis?.data} ($${props.promotionDis?.maxAmt})`
          //   : promDiscAmount < props.promotionDis?.maxAmt
          //   ? `${props.promotionDis?.data} ($${parseFloat(promDiscAmount)})` : "",

          // promDiscAmount > props.promotionDis?.maxAmt
          //   ? // ? `${props.promotionDis?.data} ($${Math.abs(
          //     //     Math.round((parseFloat(props.promotionDis?.maxAmt) * props.promotionDis?.data) / 100),
          //     //   )})`
          //     // : `${props.promotionDis?.data} ($${Math.abs(
          //     //     parseFloat(parseFloat(calc.finalAmt) * (props.promotionDis?.data / 100)).toFixed(2),
          //     //   )})`,
          //     `${props.promotionDis?.data} ($${props.promotionDis?.maxAmt})`
          //   : `${props.promotionDis?.data} ($${parseFloat(promDiscAmount)})`,
          sum: props.promotionDis?.data,
        },
      // getPath() === LISTINGPAGES.BID || getPath() === LISTINGPAGES.MYBID
      //   ? { label: 'Final Dis(%)', check: formatDecimal(calc.bidFinalDisc), sum: formatDecimal(total.bid_final_disc) }
      //   : { label: 'Final Dis(%)', check: formatDecimal(calc.finalBack), sum: formatDecimal(total.finalBack) },
      // getPath() !== LISTINGPAGES.BID && getPath() !== LISTINGPAGES.MYBID
      //   ? { label: 'Final Pr/Ct', check: formatDecimal(calc.finalCtPr), sum: formatDecimal(total.finalCtPr) }
      //   : { label: 'Final Pr/Ct', check: formatDecimal(calc.bidFinalPr), sum: formatDecimal(total.bid_final_Pr) },
      // getPath() !== LISTINGPAGES.BID && getPath() !== LISTINGPAGES.MYBID
      //   ? { label: 'Final Amount', check: formatDecimal(calc.finalAmt), sum: formatDecimal(total.finalAmt) }
      //   : { label: 'Final Amount', check: formatDecimal(calc.bidFinalAmt), sum: formatDecimal(total.bid_final_amt) },
      getPath() !== LISTINGPAGES.BID &&
        getPath() !== LISTINGPAGES.MYBID &&
        getPath() !== LISTINGPAGES.DEALTHEOFDAY &&
        getPath() !== LISTINGPAGES.MYDEALOFDAYLIST && {
          label: 'Final Dis(%)',
          check: props.promotionDis?.data !== 0 || props.showDis?.data !== 0
            ? parseFloat((1 - profinalNetCtPr / parseFloat(calc.avgRapByCrt)) * -100).toFixed(2)
            : formatDecimal(calc.finalBack),
          sum: formatDecimal(total.finalBack),
        },

      getPath() !== LISTINGPAGES.BID &&
        getPath() !== LISTINGPAGES.MYBID &&
        getPath() !== LISTINGPAGES.DEALTHEOFDAY &&
        getPath() !== LISTINGPAGES.MYDEALOFDAYLIST && {
          label: 'Final Pr/Ct',
          check: props.promotionDis?.data !==0 || props.showDis?.data !== 0 ? formatDecimal(profinalNetCtPr) : formatDecimal(calc.finalCtPr),
          sum: formatDecimal(total.finalCtPr),
        },
      getPath() !== LISTINGPAGES.BID &&
        getPath() !== LISTINGPAGES.MYBID &&
        getPath() !== LISTINGPAGES.DEALTHEOFDAY &&
        getPath() !== LISTINGPAGES.MYDEALOFDAYLIST && {
          label: 'Final Amount',
          check: formatNumber(profinalNetValue),

          sum: formatDecimal(total.finalAmt),
        },
    ];
  }, [
    props.promotionDis?.data,
    props.promotionDis?.maxAmt,
    selectedRows,
    sum?.avgDiscount,
    sum?.count,
    sum?.rapPrice,
    sum?.totalAmount,
    sum?.totalCarat,
    sum?.totalPricePerCarat,
    term,
  ]);

  const PATH = getPath() !== LISTINGPAGES.UPCOMING;

  const dealContent = props?.myDealOfTheDay
    ? [
        { label: 'Deal Disc', check: formatDecimal(sum?.avgDiscount) },
        { label: 'Deal Total Pr/ct', check: formatDecimal(sum?.totalPricePerCarat) },
        { label: 'Deal Amount', check: formatDecimal(sum?.totalAmount) },
      ]
    : [];

  if (isMobile())
    return (
      <>
        {/* <div className="diamondListLegends">
          <p>
            <span className="lDot available"></span>
            <span className="lName">A (Available)</span>
          </p>
          <p>
            <span className="lDot ibp"></span>
            <span className="lName">I (In Business Process)</span>
          </p>
          <p>
            <span className="lDot bid"></span>
            <span className="lName">B (In Bid)</span>
          </p>
          <p>
            <span className="lDot upcoming"></span>
            <span className="lName">U (Upcoming)</span>
          </p>
          <p>
            <span className="lDot expoGold"></span>
            <span className="lName">SW (Show)</span>
          </p>
        </div> */}
        <div className="diamondListLegends">
          <p>
            <span className="lDot available"></span>
            <span className="lName available">A (Available)</span>
          </p>
          <p>
            <span className="lDot ibp"></span>
            <span className="lName ibp">I (In Business)</span>
          </p>
          <p>
            <span className="lDot bid"></span>
            <span className="lName bid">B (Bid)</span>
          </p>
          <p>
            <span className="lDot upcoming"></span>
            <span className="lName upcoming">U (New Arrival)</span>
          </p>
          <p>
            <span className="lDot expoGold"></span>
            <span className="lName expoGold">S (Show)</span>
          </p>
        </div>
        <div className="diamondListSummary">
          <div className="diamondListSummaryWrap">
            {content.splice(0, 6).map((item) => {
              return (
                <p>
                  <span className="sVal">{item.check}</span>
                  <span className="sTitle">{item.label}</span>
                </p>
              );
            })}
          </div>
        </div>
      </>
    );

  if (!isArray(selectedRows) || isEmpty(selectedRows)) return null;

  return (
    <div className={`selectStoneValueBlock ${profilListing}`} style={blockStyle}>
      <div className="scroll_wrap">
        {content.map((item, i) => {
          return (
            <div className="selectStoneValueItem" key={item.label + i}>
              <span className="selectStoneLabel">
                {item.label && item.label}
                {item.label && ':'}{' '}
              </span>
              <span className="selectStopnValue">
                <span className="redColor">
                  {item.label === 'Avg. Dis(%)' || item.label === 'Final Dis(%)'
                    ? item.check > 0
                      ? `+${item.check}`
                      : item.check
                    : item.check}
                </span>
              </span>
            </div>
          );
        })}
        {!!dealContent.length && (
          <div style={{ marginLeft: '250px', display: 'flex' }}>
            {dealContent.map((item, i) => {
              return (
                <div className="selectStoneValueItem" key={item.label + i}>
                  <span className="selectStoneLabel" style={{ color: '#db1c1c' }}>
                    {item.label && item.label}
                    {item.label && ':'}{' '}
                  </span>
                  <span className="selectStopnValue">
                    <span className="redColor" style={{ color: '#0027f1' }}>
                      {item.label === 'Avg. Dis(%)' || item.label === 'Final Dis(%)'
                        ? item.check > 0
                          ? `+${item.check}`
                          : item.check
                        : item.check}
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
        )}

        {getPath() !== LISTINGPAGES.BID &&
          getPath() !== LISTINGPAGES.MYBID &&
          getPath() !== LISTINGPAGES.DEALTHEOFDAY &&
          getPath() !== LISTINGPAGES.MYDEALOFDAYLIST &&
          PATH && (
            <div className="selectStoneValueItem">
              <span className="selectStoneLabel">TERMS : </span>
              <select name="term" value={term} onChange={(e) => setTerm(e.target.value)}>
                <option value="" disabled selected hidden>
                  Select Terms
                </option>
                {SELECT_STONE_TERMS_NEW.map((term) => (
                  <option key={term?.id} value={term?.id}>
                    {term?.name}
                  </option>
                ))}
              </select>
            </div>
          )}
      </div>
      {!props.hideClearAll && (
        <div className="searchStoneClose" onClick={clearSelection}>
          Clear all
        </div>
      )}
    </div>
  );
};

export default React.memo(StoneTotal);
